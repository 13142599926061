import React from 'react';
import Circle from '../../assets/img/circle.svg';
import { useNavigate } from 'react-router-dom';
export default function Transform() {
  const navigate = useNavigate();  // Initialize navigate
  return (
    <div className='md:mx-3 mx-4 pt-3'>
      <div className='bg-secondary text-white w-100 xl:my-8 my-1  bg-pattern bg-contain bg-no-repeat bg-right-bottom rounded-md'>
        <div className='p-10'>
          <div className='font-medium text-center md:text-base lg:text-2xl  md:w-max lg:w-6/5 md:mx-auto'>Transform Your<span className='relative p-5'><img src={Circle} alt="" className='absolute top-1/2 left-0 transform -translate-y-1/2' /> <span >Career</span></span>Path</div>
          <div className='md:text-base text-sm py-8 text-center md:w-max lg:w-6/5 md:mx-auto'>
            Discover personalized coaching,<span className="inline md:hidden"><br /></span> events, mapping <span className="hidden md:inline"><br /></span>
            and counseling tailored to your career needs
          </div>
          <div className="flex justify-center">
            <button className=" md:px-14 px-8 py-2.5 text-base font-medium tracking-wider text-secondary transition-colors duration-300 transform w-auto md:mx-4 focus:outline-none bg-white rounded-full "
              onClick={() => navigate('/trainings')}
            >
              Find Your Path
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}
