import React, { useEffect } from 'react';
import policy from "../assets/img/Policy.png";
import CreateNavbar from '../components/Navbar/Navbar';

const Privacy: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="flex overflow-y-auto flex-col">
            <CreateNavbar page='about' />
            <div className="px-10 md:px-14 flex md:justify-center justify-center mt-8 md:mt-4 md:flex text-center">
                <h2 className="font-sans font-bold  mb-4 text-2xl md:text-3xl text-black">
                    PRIVACY POLICY
                </h2>
            </div>
            <div className="px-10 md:px-24 flex md:justify-end justify-center mt-8 md:mt-7  md:flex">
                <img src={policy} alt="privacy" className="w-96 h-auto" />
            </div>
            {/* Content Section */}
            <div className="flex flex-col lg:flex-row justify-center mt-8 md:-mt-96">

                {/* Text content */}
                <div className=" text-left px-10 md:px-24">

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color">
                        1. INTRODUCTION
                    </h2>

                    <p className="font-normal text-sm mb-4 font-sans">
                        This Privacy Policy outlines how SkillJourney LLP ("we," "us," <span className="hidden md:inline "><br /></span>"our") collects, uses, and protects your
                        personal information when <span className="hidden md:inline "><br /></span> you use SkillJourney NorthStar, our
                        career guidance platform. By <span className="hidden md:inline "><br /></span> accessing and using our web application,
                        you agree to the terms <span className="hidden md:inline "><br /></span> of this Privacy Policy.
                    </p>

                    {/* Additional content sections */}

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color">
                        2. COMPANY INFORMATION
                        <ul className="font-normal font-sans  text-sm list-disc  text-black pt-4 pl-8">
                            <li>Company Name: SkillJourney LLP</li>
                            <li>Registered Address: A303, Samrajya Balwantpuram, Shivtirtha Nagar,<span className="hidden md:inline"><br /></span> Paud Road, Pune 411038</li>
                            <li>Current Address: SkillJourney LLP, 5th Floor, CC&Co,<span className="hidden md:inline"><br /></span> CornerStone Premises, Prabhat Road, Pune 411004</li>
                            <li>Contact Email: <a href="mailto:support@skilljourney.in" className="text-highlight-color">support@skilljourney.in</a></li>
                        </ul>
                    </h2>


                    {/* Section 3 */}

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        3. SERVICE PROVIDED
                        <h3 className="font-semibold font-sans text-lg mb-4 text-black pt-4">Personal Information
                            <p className='font-normal font-sans text-sm pt-4'>We collect the following personal information from you:</p>
                            <ul className=" font-normal font-sans text-sm  list-disc  text-black pl-8">


                                <li>Name</li>
                                <li>Email address</li>
                                <li>Contact number</li>
                                <li>Career-related information (e.g., career goals, current job status, education background)</li>
                                <li>Work preferences as per your answers in our questionnaire</li>
                                <li>Personality traits as per your answers to our questionnaire</li>
                                <li>Resume</li>

                            </ul>
                            <p className='font-normal text-sm font-sans'>This information is voluntarily provided by individuals seeking career guidance and development services.</p>
                        </h3>

                        <h3 className="font-semibold font-sans text-lg mb-4 text-black">Usage Information
                            <p className='font-normal text-sm font-sans pt-4'>We collect information on how you use our web application, including:</p>
                            <ul className=" font-normal text-sm  list-disc  font-sans pl-8">


                                <li>IP address</li>
                                <li>Browser type</li>
                                <li>Pages visited</li>
                                <li>Time spent on the site</li>
                                <li>Referral source</li>
                            </ul>
                        </h3>

                        <h3 className="font-semibold font-sans text-lg mb-4 text-black">Payment Information
                            <p className='font-normal font-sans space-y-2 text-sm pt-4'>For users subscribing to the Premium Tier, we collect payment information necessary to process the subscription fee.
                            </p>
                        </h3>
                    </h2>


                    {/* Section 4 */}
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        4.  How We Use Your Information
                        <h3 className="font-semibold text-lg mb-4 text-black font-sans pt-4">Personal Information
                            <p className='font-normal font-sans text-sm pt-4'>We use your personal information to:</p>
                            <ul className=" font-normal text-sm font-sans list-disc  pl-8">


                                <li>Create and manage your account</li>
                                <li>Provide personalized career guidance</li>
                                <li>Communicate with you regarding your account and our services</li>
                                <li>Improve our services and user experience</li>
                            </ul>

                        </h3>
                        <p className='font-normal text-sm  font-sans text-black pt-2'>Personal information collected by SkillJourney LLP is used to create customized career journeys for our customers. This includes matching individuals with appropriate
                            career counsellors and mentors associated with SkillJourney LLP.
                            We do not sell or expose this information online for
                            commercial purposes.
                        </p>
                        <p className='font-normal font-sans text-sm pt-2 text-black'>SkillJourney LLP may share personal information
                            with career counsellors and mentors to facilitate the provision
                            of our services. These third parties are contractually obligated to
                            maintain the confidentiality and security of the information provided to them.</p>

                        <h3 className="font-semibold text-lg mb-4 text-black font-sans pt-4">Usage Information
                            <p className='pt-4 font-normal font-sans text-sm'>We use usage information to:</p>
                            <ul className="pl-8 font-normal text-sm  list-disc  font-sans">


                                <li>Analyze how our web application is used</li>
                                <li>Improve the functionality and performance of our platform</li>
                                <li>Ensure the security and integrity of our services</li>

                            </ul>

                        </h3>

                        <h3 className="font-semibold font-sans pt-4 text-lg mb-4 text-black">Payment Information

                            <p className='font-normal text-sm font-sans pt-4'>We use your payment information to process transactions for the Premium Tier subscription.
                            </p>
                        </h3>
                    </h2>

                    {/* Section 5 */}
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        5.  Sharing Your Information
                        <h3 className="font-semibold text-lg mb-4 text-black font-sans pt-4">Third-Party Service Providers
                            <p className='pt-4 font-normal font-sans text-sm'>We share your information with third-party service providers, including</p>
                            <ul className="pl-8 font-normal text-sm font-sans list-disc ">


                                <li>OpenAI's ChatGPT Engine: We share user data anonymously to enhance our career guidance algorithm. While we strive to ensure the accuracy of the information provided by OpenAI, we do not guarantee its validity.</li>
                                <li>Payment Processors: We share payment information with secure payment processing providers to facilitate subscription transactions.</li>
                            </ul>
                        </h3>
                        <h3 className="font-semibold pt-4 text-lg mb-4 font-sans text-black">Legal Requirements
                            <p className='font-normal font-sans text-sm text-black pt-4'>WeWe may disclose your information if required to do so by law or in response to valid requests by public authorities.
                            </p>
                        </h3>
                    </h2>


                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        6.  DATA SECURITY
                        <p className='font-normal font-sans text-sm pt-4 text-black'>We implement appropriate security measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. However, no method of transmission over the internet or method of electronic storage is 100% secure.</p>
                    </h2>

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        7.  USER RIGHTS
                        <h3 className="font-semibold text-lg mb-4 text-black font-sans pt-4">Access and Correction
                            <p className='font-normal text-sm font-sans pt-4'>You have the right to access and update your personal information by logging into your account and editing your profile.
                            </p>
                        </h3>
                        <h3 className="font-semibold text-lg mb-4 text-black font-sans pt-4">Data Retention and Deletion
                            <p className='font-normal text-sm font-sans pt-4'>SkillJourney LLP retains personal information only for as long as necessary to fulfil the purposes outlined in this Privacy
                                Policy or as required by law. We regularly review and update our
                                data retention practices to ensure compliance with legal requirements.
                            </p>
                        </h3>
                        <p className='font-normal text-sm font-sans text-black'>
                            You can request the deletion of your account and personal
                            information by contacting us at <a href="mailto:support@skilljourney.in" className="text-highlight-color">support@skilljourney.in.</a> We will
                            process your request in accordance with applicable laws.
                        </p>
                    </h2>

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        8.  CHANGES TO THIS PRIVACY POLICY
                        <p className='font-normal text-sm font-sans pt-4 text-black'>We may update this Privacy Policy
                            from time to time. Any changes will be posted on this page with
                            an updated revision date. We encourage you to review this Privacy
                            Policy periodically to stay informed about how
                            we are protecting your information.
                        </p>
                    </h2>

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        9.  CONTACT INFORMATION
                        <p className='font-normal text-sm font-sans pt-4 text-black'>If you have any questions or concerns about this Privacy Policy or our data practices, please contact us at:</p>

                        <ul className=" font-normal text-sm text-black list-disc font-sans pl-8">

                            <li>Email: <a href="mailto:support@skilljourney.in" className="text-highlight-color">legal@skilljourney.in</a></li>
                        </ul>
                    </h2>

                    <p className='font-normal font-sans text-sm my-4'>By using SkillJourney NorthStar, you acknowledge that you have read, understood, and agree to this Privacy Policy.
                    </p>

                </div>
            </div>
        </div>


    );
}

export default Privacy;
