// Define the type for the messages object
type MessageColors = {
    [color: string]: string[];
  };
  
  // Define the messages object with the MessageColors type
  export const messages: MessageColors = {
    "#4CAF50": ["submitted", "successfully", "paid"],
    red: [
      "not inserted",
      "not deleted",
      "ValueError",
      "Failed",
      "not found",
      "exceeded the editing limit",
      "Wrong",
      "problem",
    ],
    "#FFD700": ["No changes detected"],
  };
  
  // Type for the messagesColor function
  export const messagesColor = (message: string): string => {
    for (const [key, values] of Object.entries(messages)) {
      if (values.some((v) => message.includes(v))) {
        return key;
      }
    }
    return "#FFD700"; // Default color if no match is found
  };
  