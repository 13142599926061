import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar'; // Adjust the import path as necessary
import NoSessions from './NoSessions';
import { fetchBookings } from '../../api/Counsellor';
import { isUserTokenValid } from '../common/ValidateToken';
import LoadingSpinner from '../common/LoadingSpinner';

// Define the structure for session data
interface Session {
  id: string; // Use meeting_id as id
  counselor: {
    name: string;
    email: string;
    contact: number;
    profileUrl: string;
    bio: string;
    address: string;
    specialty?: string; // Optional
    rating: number;
  };
  date: string;
  time: string;
  timestamp:any;
  status: 'Upcoming' | 'Completed' | 'Cancelled';
  meetingUrl: string; // URL for the meeting
}

// Sample data for booked sessions
const sampleData = {
  "bookings": [
    // Your JSON data here
  ]
};

const BookedSessionsPage: React.FC = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<'All' | 'Upcoming' | 'Completed' | 'Cancelled'>('All');
  const [bookedSessions, setBookedSessions] = useState<Session[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Loading state

  // Handle navigation to detailed view of a session
  const handleSessionClick = (session: Session) => {
    navigate(`/sessions/${session.id}`, { state: { session } });
  };

  const handleBookClick = () => {
    navigate('/counselling');
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);

    // Simulate fetching data from an API
    // Replace this with your actual data fetching logic
    const fetchData = async () => {
      // Simulate fetching data
    try{
      setLoading(true); // Start loading
      const isValid = await isUserTokenValid();
      if(!isValid){
        navigate('/login');
      }
      const response = await fetchBookings(); // Replace this with actual API call
      const sessions = response.bookings.map((booking: any) => ({
        id: booking.meeting_id,
        counselor: {
          name: booking.counsellor_info.name || 'Unknown',
          email: booking.counsellor_info.email || '',
          contact: booking.counsellor_info.contact || 0,
          profileUrl: booking.counsellor_info.profile_url || 'https://via.placeholder.com/150',
          bio: booking.counsellor_info.bio || '',
          address: booking.counsellor_info.address || '',
          specialty: booking.counsellor_info.specialty || '', // Specialty is optional
          rating: booking.counsellor_info.rating || 0.0,
        },
        date: booking.date,
        time: booking.time,
        status: booking.status as 'Upcoming' | 'Completed' | 'Cancelled',
        meetingUrl: booking.meeting_url,
        timestamp:booking.timestamp,
      }));
      setBookedSessions(sessions);
    } catch (error: any) {
      console.log(error.message);
    } finally {
      setLoading(false); // Stop loading
    }
    };

    fetchData();
  }, []);

  if (loading) {
    return <LoadingSpinner />; // Show spinner while loading
  }

  const filters = bookedSessions
    .map(Session => Session.status)
    .filter((value, index, self) => self.indexOf(value) === index);

  // Filter sessions based on the selected filter
  const filteredSessions = bookedSessions.filter(session =>
    filter === 'All' || session.status === filter
  );

  return (
    <>
      <CreateNavbar page='home' />
      <div className="bg-gray-100 min-h-screen p-4 md:p-6 lg:p-8 font-sans">
        {/* Check if there are no booked sessions */}
        {bookedSessions.length === 0 ? (
          <NoSessions handleBookClick={handleBookClick} />
        ) : (
          <>
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-extrabold text-center mb-6 md:mb-8 lg:mb-10 text-gray-900">Your Booked Sessions</h1>

            {/* Filter Options */}
            <div className="mb-4 md:mb-6 flex justify-end">
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value as 'All' | 'Upcoming' | 'Completed' | 'Cancelled')}
                className="px-3 py-2 md:px-4 md:py-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange text-sm md:text-base"
              >
                <option value="All">All Sessions</option>
                {filters.map((status, index) => (
                    <option key={index} value={status}>
                      {status}
                    </option>
                  ))}
               
              </select>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
              {filteredSessions.map((session) => (
                <div
                  key={session.id}
                  className="bg-white p-4 sm:p-6 lg:p-8 rounded-lg shadow-md border-t-4 border-custom-orange transition-transform transform hover:scale-105 hover:shadow-2xl flex flex-col"
                >
                  <div className="flex items-center mb-4 sm:mb-6">
                    <img
                      src={session.counselor.profileUrl}
                      alt={session.counselor.name}
                      className="w-20 h-20 sm:w-28 sm:h-28 lg:w-32 lg:h-32 rounded-full border-4 border-blue-500 mr-4"
                    />
                    <div>
                      <h3 className="text-lg sm:text-xl lg:text-2xl font-semibold text-gray-800 mb-1 sm:mb-2">{session.counselor.name}</h3>
                      <p className="text-gray-600 text-sm sm:text-base mb-1"><strong>Date:</strong> {session.date}</p>
                      <p className="text-gray-600 text-sm sm:text-base mb-1"><strong>Time:</strong> {session.time}</p>
                      <p className={`text-gray-600 text-sm sm:text-base font-semibold ${session.status === 'Completed' ? 'text-green-500' : session.status === 'Cancelled' ? 'text-red-500' : 'text-yellow-500'}`}>
                        <strong>Status:</strong> {session.status}
                      </p>
                    </div>
                  </div>
                  <div className="flex-grow flex items-end">
                    <button
                      onClick={() => handleSessionClick(session)}
                      className="w-full px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default BookedSessionsPage;
