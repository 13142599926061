// GridImage.tsx
import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const GridOverlay = styled.div<{ rows: number; cols: number }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-rows: repeat(${props => props.rows}, 1fr);
  grid-template-columns: repeat(${props => props.cols}, 1fr);
`;

// ------------testing purpose ----------
// const GridCell = styled.div`
//   border: 1px solid transparent;
//   &:hover {
//     border: 1px solid red; /* Optional: Highlight cell on hover */
//   }
// `;
//--------------------------------------

const GridCell = styled.div`border: 1px solid transparent;`;

interface GridImageProps {
  src: string;
  rows: number;
  cols: number;
  onCellClick: (cellNumber: number) => void;
  containerClassName?: string;
  imageClassName?: string;
}

const GridImageSteaper: React.FC<GridImageProps> = ({ src, rows, cols, onCellClick, containerClassName, imageClassName }) => {
  const handleCellClick = (row: number, col: number) => {
    const cellNumber = row * cols + col + 1;
    onCellClick(cellNumber);
  };

  return (
    <Container className={containerClassName}>
      <img src={src} className={imageClassName} alt='ImageSteaper' />
      <GridOverlay rows={rows} cols={cols}>
        {Array.from({ length: rows * cols }).map((_, index) => {
          const row = Math.floor(index / cols);
          const col = index % cols;
          return (
            <GridCell key={index} onClick={() => handleCellClick(row, col)} />
          );
        })}
      </GridOverlay>
    </Container>
  );
};

export default GridImageSteaper;
