import React,{useEffect} from 'react';
import terms from "../assets/img/terms.png";
import CreateNavbar from '../components/Navbar/Navbar';
const Terms: React.FC = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div className="flex overflow-y-auto flex-col">
            <CreateNavbar page='about' />
            <div className="px-10 md:px-14 flex md:justify-center justify-center mt-8 md:mt-4 md:flex text-center">
            <h2 className="font-sans font-bold text-2xl md:text-3xl mb-4 text-black">
                         TERMS AND CONDITIONS
                    </h2>
                </div>
            <div className="px-10 md:px-24 flex md:justify-end justify-center mt-8 md:mt-9  md:flex">
                    <img src={terms} alt="terms" className="w-96 h-auto" />
                </div>
            {/* Content Section */}
            <div className="flex flex-col lg:flex-row justify-center mt-8 md:-mt-72">
                
                {/* Text content */}
                <div className=" text-left px-10 md:px-24">
                    
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color">
                        1. INTRODUCTION
                    </h2>
                    
                    <p className="font-normal text-sm mb-4 font-sans">
                    This Privacy Policy outlines how SkillJourney LLP ("we," "us," <span className="hidden md:inline "><br /></span>"our") collects, uses, and protects your 
                    personal information when <span className="hidden md:inline "><br /></span> you use SkillJourney NorthStar, our
                     career guidance platform. By <span className="hidden md:inline "><br /></span> accessing and using our web application, 
                    you agree to the terms <span className="hidden md:inline "><br /></span> of this Privacy Policy.
                    </p>

                    {/* Additional content sections */}

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color ">
                        2. COMPANY INFORMATION
                        <ul className=" font-normal font-sans text-sm  list-disc text-black pt-4 pl-8">
                        <li>Company Name: SkillJourney LLP</li>
                        <li>Registered Address: A303, Samrajya Balwantpuram, Shivtirtha Nagar,<span className="hidden md:inline "><br /></span> Paud Road, Pune 411038</li>
                        <li>Current Address: SkillJourney LLP, 5th Floor, CC&Co,<span className="hidden md:inline"><br /></span> CornerStone Premises, Prabhat Road, Pune 411004</li>
                        <li>Contact Email: <a href="mailto:support@skilljourney.in" className="text-highlight-color">support@skilljourney.in</a></li>

                    </ul>
                    </h2>
                   

                    {/* Section 3 */}

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color">
                        3. SERVICE PROVIDED
                    </h2>
                    
                    <p className="font-normal text-sm mb-4 font-sans">
                    SkillJourney NorthStar is a career guidance platform that assists users in planning and tracking their career journeys. The SkillJourney app collects the user information using their profile (uploaded resume) and a questionnaire and uses this information to generate a career journey. The generation of this career journey is done using SkillJourney’s internal algorithms and OpenAI’s generative AI capabilities.
                    </p>
                  

                    {/* Section 4 */}
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        4. USER RESPONSIBILITIES
                        <h3 className="font-semibold text-lg mb-4 text-black font-sans pt-4">Personal Information
                        <p className="font-normal text-sm mb-4 font-sans pt-4">
                        Users must login using one of the social login options to access the services provided by SkillJourney NorthStar. For Career Journey creation, users must provide accurate and complete information.
                    </p>
                  

                    </h3>
                   

                    <h3 className="font-semibold text-lg mb-4 text-black font-sans ">Prohibited Actions
                    <p className='pt-4 font-normal font-sans text-sm text-black'>Users are prohibited from:</p>
                        <ul className="pl-8 font-normal text-sm  list-disc font-sans">
                           

                            <li>Posting offensive content</li>
                            <li>Hacking or attempting to hack the system</li>
                            <li>Spamming or sending unsolicited messages</li>
                            <li>Spoofing or phishing attempts</li>

                        </ul>

                    </h3>

                  
                    </h2>
                    
                    {/* Section 5 */}
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        5.  PAYMENT TERMS
                        <h3 className="font-semibold text-lg mb-4 text-black font-sans pt-4">Fees
                        <ul className="pt-4 font-normal text-sm font-sans list-disc pl-8">
                            
                            <li>Basic Tier: Free</li>
                            <li>Premium Tier: Rs. 999/- per annum</li>
                        </ul>
                    </h3>
                    <h3 className="font-semibold  text-lg mb-4 font-sans text-black">Refund Policy
                        <p className='font-normal font-sans text-sm text-black pt-4'>If SkillJourney NorthStar is unable to generate a career journey for the user, a refund will be issued. Users can request a refund by contacting <a href="mailto:support@skilljourney.in" className="text-highlight-color">support@skilljourney.in</a> with their username and a screen recording of the issue.
                        </p>
                    </h3>
                    </h2>
                    

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        6.  PRIVACY POLICY
                        <p className='font-normal font-sans text-sm pt-4 text-black'>For details on how user data is collected, used, and protected, please refer to our Privacy Policy.</p>
                    </h2>
                    
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        7.  INTELLECTUAL PROPERTY
                       
                        <p className='font-normal text-black text-sm font-sans pt-4'>All content on SkillJourney NorthStar is owned by SkillJourney LLP. Users are allowed to use the content for personal purposes only and not for commercial purposes.
                        </p>
                 </h2>
                   
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        8.  LIMITATION OF LIABILITY
                        <ul className="pt-4 pl-8 text-black font-normal text-sm font-sans list-disc">
                            
                            <li>SkillJourney uses OpenAI’s ChatGPT engine to assist in creating career journeys. The generated plan is a guidance tool and does not guarantee any job, internship, or promotion. The implementation and outcomes of the plan are the user's responsibility.</li>
                            <li>SkillJourney is not liable for any loss of information after the plan is generated.</li>
                            <li>SkillJourney shares user data anonymously with OpenAI’s ChatGPT engine. While SkillJourney strives to ensure the accuracy of information, it does not guarantee the validity of data provided by OpenAI.</li>
                        </ul>
                    </h2>

                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        9.  TERMINATION CLAUSE
                       
                        <p className='font-normal text-black text-sm font-sans pt-4'>SkillJourney reserves the right to terminate a user’s account if the user is found hacking the system, using it inappropriately, or indulging in prohibited actions as mentioned in these Terms.
                        </p>
                 </h2>

                 <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        10.  GOVERNING LAW
                       
                        <p className='font-normal text-black text-sm font-sans pt-4'>These Terms are governed by Indian laws and fall under the jurisdiction of Pune, India.
                        </p>
                 </h2>
                   
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        11. CHANGES TO TERMS
                        <p className='font-normal text-sm font-sans text-black pt-4'>SkillJourney may update these Terms from time to time due to:</p>
                        <ul className=" font-normal text-sm text-black list-disc font-sans pl-8">
                      
                        <li>Legal changes in Indian company law</li>
                            <li>New market operations</li>
                            <li>New partnerships or vendor relationships</li>
                            <li>Other reasonable events requiring an update</li>
                            <p className='font-normal text-sm font-sans'>Users will be informed of changes through updates on the website with a visible indication of changes.</p>
                        </ul>
                 
                    </h2>
                    <h2 className="font-sans font-bold md:text-2xl text-xl mb-4 text-privacy-color mt-6">
                        12.  DISPUTE RESOLUTION
                       
                        <p className='font-normal text-black text-sm font-sans pt-4'>For any disputes, please contact <a href="mailto:support@skilljourney.in" className="text-highlight-color">support@skilljourney.in.</a>
                        </p>
                 </h2>
                   
                </div>
</div>
</div>


    );
}

export default Terms;
