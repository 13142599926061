import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar'; // Adjust the import path as necessary
import NoSessions from '../CounsellorUser/NoSessions';

// Define the structure for session data
interface Session {
  id: number;
  user: {
    name: string;
    email: string;
  };
  counselor: {
    name: string;
    specialty: string;
    photoUrl: string;
  };
  date: string;
  time: string;
  status: 'Upcoming' | 'Completed' | 'Cancelled';
}

// Sample data for sessions
const bookedSessions: Session[] = [
  {
    id: 1,
    user: {
      name: 'Jane Doe',
      email: 'jane.doe@example.com',
    },
    counselor: {
      name: 'Mr. Bapat',
      specialty: 'Ex. Defence',
      photoUrl: 'https://via.placeholder.com/150', // Placeholder image
    },
    date: '2024-09-15',
    time: '10:00 AM',
    status: 'Upcoming',
  },
  {
    id: 2,
    user: {
      name: 'John Smith',
      email: 'john.smith@example.com',
    },
    counselor: {
      name: 'Aadarsh Iyer',
      specialty: 'Technology',
      photoUrl: 'https://via.placeholder.com/150', // Placeholder image
    },
    date: '2024-09-18',
    time: '2:00 PM',
    status: 'Completed',
  },
  {
    id: 3,
    user: {
      name: 'Emily Davis',
      email: 'emily.davis@example.com',
    },
    counselor: {
      name: 'Praddyumna Bapat',
      specialty: 'Marketing',
      photoUrl: 'https://via.placeholder.com/150', // Placeholder image
    },
    date: '2024-09-20',
    time: '11:00 AM',
    status: 'Cancelled',
  },
];

const CounselorBookedSessionsPage: React.FC = () => {
  const navigate = useNavigate();
  const [filter, setFilter] = useState<'All' | 'Upcoming' | 'Completed' | 'Cancelled'>('Upcoming');

  // Handle navigation to detailed view of a session
  const handleSessionClick = (id: number) => {
    navigate(`/sessions/${id}`);
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  // Filter sessions based on the selected filter
  const filteredSessions = bookedSessions.filter(session =>
    filter === 'All' || session.status === filter
  );

  return (
    <>
      <CreateNavbar page='home' />
      <div className="bg-gray-50 min-h-screen p-6 lg:p-8 font-sans">
        {/* Check if there are no booked sessions */}
        {bookedSessions.length === 0 ? (
        //   <NoSessions />
          <>
          </>
        ) : (
          <>
            <h1 className="text-2xl md:text-3xl lg:text-4xl font-bold text-center mb-8 text-gray-800">Booked Sessions by Users</h1>

            {/* Filter Options */}
            <div className="mb-6 flex justify-end">
              <select
                value={filter}
                onChange={(e) => setFilter(e.target.value as 'All' | 'Upcoming' | 'Completed' | 'Cancelled')}
                className="px-4 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-custom-orange focus:border-custom-orange text-sm md:text-base bg-white"
              >
                <option value="All">All Sessions</option>
                <option value="Upcoming">Upcoming</option>
                <option value="Completed">Completed</option>
                <option value="Cancelled">Cancelled</option>
              </select>
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 gap-6 md:gap-8">
              {filteredSessions.map((session) => (
                <div
                  key={session.id}
                  className="bg-white p-6 rounded-lg shadow-lg border-t-4 border-custom-orange transition-transform transform hover:scale-105 hover:shadow-2xl flex flex-col"
                >
                  <div className="flex items-center mb-6">
                    <img
                      src={session.counselor.photoUrl}
                      alt={session.counselor.name}
                      className="w-24 h-24 sm:w-32 sm:h-32 lg:w-40 lg:h-40 rounded-full border-4 border-blue-500 mr-4"
                    />
                    <div>
                      <h3 className="text-xl font-semibold text-gray-800 mb-2">{session.counselor.name}</h3>
                      <p className="text-gray-600 text-base mb-1"><strong>Specialty:</strong> {session.counselor.specialty}</p>
                      <p className="text-gray-600 text-base mb-1"><strong>Date:</strong> {session.date}</p>
                      <p className="text-gray-600 text-base mb-1"><strong>Time:</strong> {session.time}</p>
                      <p className={`text-base font-semibold ${session.status === 'Completed' ? 'text-green-600' : session.status === 'Cancelled' ? 'text-red-600' : 'text-yellow-600'}`}>
                        <strong>Status:</strong> {session.status}
                      </p>
                    </div>
                  </div>
                  <div className="mb-4">
                    <h4 className="text-lg font-semibold text-gray-700 mb-2">User Details</h4>
                    <p className="text-gray-600 text-base mb-1"><strong>Name:</strong> {session.user.name}</p>
                    <p className="text-gray-600 text-base"><strong>Email:</strong> {session.user.email}</p>
                  </div>
                  <div className="flex-grow flex items-end">
                    <button
                      onClick={() => handleSessionClick(session.id)}
                      className="w-full px-4 py-2 bg-blue-600 text-white font-semibold rounded-lg shadow-md hover:bg-blue-700 transition-colors duration-300"
                    >
                      View Details
                    </button>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default CounselorBookedSessionsPage;
