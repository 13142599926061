import React, { useEffect } from 'react';

const ClarityScript: React.FC = () => {
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.clarity.ms/tag/nml2oyuf8h';
    script.async = true;
    script.onload = () => {
      console.log('Clarity script loaded successfully');
    };
    script.onerror = () => {
      console.error('Failed to load Clarity script');
    };

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return null; // This component does not render anything to the DOM
};

export default ClarityScript;
