import React from "react";

interface TitleProps {
    title: string;
    subTitle: string | undefined;
  }

  const Title: React.FC<TitleProps> = ({ title, subTitle }) => {
  return (
    <div className="  pt-6 md:pt-2">
      <h2 className="text-xl md:text-2xl font-sans font-bold tracking-wide">{title}</h2>
      <p className="text-base text-description-text">
       {subTitle}
      </p>
    </div>
  );
}

export default Title;