import { IconButton, Snackbar } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { messagesColor } from "./messages";

interface SnackbarComponentProps {
  isSnackbarOpen: boolean;
  setIsSnackbarOpen: (open: boolean) => void;
  backendMessage: string;
}

export const SnackbarComponent: React.FC<SnackbarComponentProps> = ({
  isSnackbarOpen,
  setIsSnackbarOpen,
  backendMessage,
}) => {
  return (
    <Snackbar
      open={isSnackbarOpen}
      onClose={() => setIsSnackbarOpen(false)}
      message={backendMessage}
      action={
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={() => setIsSnackbarOpen(false)}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
          }}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      }
      ContentProps={{
        sx: {
          backgroundColor: messagesColor(backendMessage),
          color: "white",
          borderRadius: "5px",
          fontWeight: "bold",
          textAlign: "center",
          minWidth: "400px", // Adjust width as needed
          position: "fixed",
          left: "50%",
          top: "90%",
          transform: "translate(-50%, -50%)",
        }
      }}
    />
  );
};
