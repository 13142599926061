import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import defaultAvatar from '../../assets/img/Avatar.png';
import cross from '../../assets/img/cross.svg';
import Logo from '../../assets/img/skillJourneyMainLogo.svg';
import homeIcon from '../../assets/img/Contact/home.svg';
import Training from '../../assets/img/Contact/Training.svg';
import Message from '../../assets/img/Contact/message.svg';
import Products from '../../assets/img/Contact/Product.svg';
import About from '../../assets/img/Contact/about.svg';
import Fingerprint from '../../assets/img/Contact/Fingerprint.svg';
import Logout from '../../assets/img/Contact/Logout.svg';
import Journey from '../../assets/img/Contact/standard.svg';
import premium from '../../assets/img/Contact/premium.svg';
import back from '../../assets/img/Contact/back.svg';
import feedback from '../../assets/img/Contact/Feedback.svg';
import Explore from '../../Page/ExploreOpportunities';
import { FetechUserProfile } from '../../api/FetchUserProfile';
import counseelingLogo from "../../assets/img/counselling.svg";

interface NavbarProps {
  page: string;
  bgColor?: string;
}

const Navbar: React.FC<NavbarProps> = ({ page, bgColor }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);
  const [userProfileIcon, setUserProfileIcon] = useState(defaultAvatar); // Initial state with default avatar
  const [isUserLoggedIn, setUserStatus] = useState(false);
  const email = localStorage.getItem('Email');

  const location = useLocation();
  const name = localStorage.getItem('Name');

  const commonLinks = [
    { to: "/", label: "Home" },
    { to: "/trainings", label: "Trainings" },
    { to: "/counselling", label: "Counselling" },
    { to: "/comingsoon", label: "Blog" },
    { to: "/comingsoon", label: "Products" },
    { to: "/about", label: "About" }
  ];
 
const userLevel = localStorage.getItem('userLevel') || 'Standard';

const showPremiumJourney = userLevel !== 'Standard';


  const navigate = useNavigate();
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const profileIcon: any = await FetechUserProfile();
        if (profileIcon && profileIcon !== '') {
          setUserProfileIcon(profileIcon);
          setUserStatus(true);
        }
      } catch (error) {
        console.error('Error fetching user profile icon:', error);
      }
    };

    fetchUserProfile();
  }, []);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = isMenuOpen ? 'auto' : 'auto'; // Prevent scrolling when menu is open
  };
  // 
  const enableScrolling = () => {
    setIsMenuOpen(!isMenuOpen);
    document.body.style.overflow = 'auto';
  };

  const toggleDesktopMenu = () => {
    setIsDesktopMenuOpen(!isDesktopMenuOpen);
  };

  // Close desktop menu on clicking outside
  const handleCloseDesktopMenu = () => {
    if (isDesktopMenuOpen) {
      setIsDesktopMenuOpen(false);
    }
  };
  const handleLogout = async () => {
    try {
      const isOnQuestionnairePage = location.pathname.startsWith('/questionnaire'); // Adjust this based on your routing setup

      if (isOnQuestionnairePage) {
        const userConfirmed = window.confirm(
          "If you have any draft data, it will be lost."
        );
        if (userConfirmed) {
          localStorage.clear();
          navigate('/');
        }
      } else {
        localStorage.clear();
        navigate('/');
      }
    } catch (error) {
      console.error('Logout failed', error);
    }
  };
  return (
    <nav className={`${page === 'premiumJourney' ? 'fixed w-full' : 'relative'} ${bgColor || 'bg-white'} `}>
      {isDesktopMenuOpen && (
        <div
          className="fixed inset-0 z-30"
          onClick={handleCloseDesktopMenu}
        ></div>
      )}
      <div className={`max-w-full px-4 sm:px-6 lg:px-9 `}>
        <div className={`relative flex h-16 items-center justify-between`}>
          {/* Logo for Mobile View */}
          <div className="absolute inset-y-1 left-0 flex items-center sm:hidden z-10">
            <a href="/" className="inset-y-10">
              <img src={Logo} alt="Logo" className="h-20 w-[140px] md:w-[150px] md:h-10 mx-auto py-2" />
            </a>
          </div>
          {/* Mobile Menu Button */}
          <div className="absolute inset-y-0  right-2 flex items-center sm:hidden z-10">
            <button
              type="button"
              className="inline-flex items-center justify-center p-2 text-black"
              onClick={toggleMenu}
            >
              <svg
                className="h-6 w-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                  stroke="black"
                />
              </svg>
            </button>
          </div>
          {/* Desktop Menu and Extra Buttons */}
          <div className={`hidden sm:flex sm:items-center sm:justify-between w-full ${page !== 'questionnaire' ? 'mx-14' : ''}`}>
            {/* Logo */}
            {page !== 'questionnaire' ? (
              <div className="flex-shrink-0">
                <a href="/" className="inset-y-10">
                  <img src={Logo} alt="Logo" className="w-[160px] md:h-20" />

                </a>
              </div>
            ) : null}

            <div className="flex items-center space-x-4 ml-5">
              {commonLinks.map((link) => (
                <Link key={link.to} to={link.to} className="rounded-md px-3 py-2 text-base font-semibold font-sans text-black">
                  {link.label}
                </Link>
              ))}

              {page === 'header' ? !isUserLoggedIn && (
                <Link to="/login" className="rounded-full bg-custom-orange px-3 py-2 text-sm font-medium font-sans text-white">
                  Login
                </Link>
              ) : (
                <div className="relative flex items-center">
                  <img
                    src={userProfileIcon}
                    alt="Avatar"
                    className="h-10 w-10 rounded-full object-cover cursor-pointer"
                    onClick={toggleDesktopMenu} // Open desktop menu on avatar click
                  />
                </div>
              )}
            </div>
          </div>
          {/* Grey Overlay */}
          {isMenuOpen && (
            <div
              className="fixed inset-0 bg-black bg-opacity-50 z-30"
              onClick={enableScrolling} // Closes the menu if the overlay is clicked
            ></div>
          )}
          {/* Mobile Menu */}
          <div
            className={`fixed top-0 right-0 z-40 h-full w-[85%] bg-menuebar shadow-lg transform transition-transform ${isMenuOpen ? 'translate-x-0' : 'translate-x-full'} sm:hidden`}
          >
            <div className="relative flex flex-col h-full">
              {/* Centered Logo when Menu is Open */}
              <div className={`py-3 text-xl font-bold font-sans mx-auto w-60 text-black ${isUserLoggedIn ? 'border-b-2 border-bloc-color' : ''} py-2`}>

                {!isUserLoggedIn ? (
                  <a href="/" className="block">
                    <img src={Logo} alt="Journey Icon" className="h-24 w-56 pt-3 py-2" />
                  </a>
                ) : (
                  <div className="flex items-center pt-1 py-1">
                    {isUserLoggedIn && (
                      <img src={userProfileIcon} alt="Avatar" className="h-18 w-12 pt-0 rounded-full object-cover" />
                    )}

                    <div className="flex items-center pt-1 py-1">
                      {isUserLoggedIn && (
                        <>
                          {(name?.length ?? 0) > 18 ? (
                            // Split name into two lines if combined length is greater than 18
                            <div className="flex flex-col w-44 break-words overflow-hidden">
                              <span className="pl-1 text-black text-sx font-medium leading-5 font-sans truncate">
                                {name?.split(' ')[0] || ''} {/* First Name */}
                              </span>
                              <span className="pl-1 text-black text-sx font-medium leading-5 font-sans truncate">
                                {name?.split(' ')[1] || ''} {/* Last Name (if available) */}
                              </span>
                            </div>
                          ) : (
                            // Display name in a single line if combined length is 18 or less
                            <span className="pl-1 text-black text-sx font-medium leading-5 font-sans break-words truncate w-44">
                              {name || ''} {/* Fallback to an empty string if name is undefined */}
                            </span>
                          )}
                        </>
                      )}
                    </div>

                  </div>

                )}
              </div>
              {/* Premium User Text Positioned Slightly Down and Fully to the Left */}
              <div className="relative">
                {isUserLoggedIn && (
                  <div className="text-custom-blue flex justify-center text-xl font-bold font-sans ml-4">
                    Standard
                  </div>
                )}
                {/* Adjusted Back Button Circle */}
                <div
                  className={`absolute -left-6 bottom-[-70px] h-16 w-16 rounded-full bg-menuebar flex items-center justify-center cursor-pointer z-50 ${isMenuOpen ? 'block' : 'hidden'}`}
                  onClick={() => {
                    toggleMenu();
                    setIsMenuOpen(false); // Hide the menu and semi-circle on click
                  }}
                >
                  <img
                    src={back}
                    alt="Back Icon"
                    className="h-6 w-6 transform -translate-x-2" // Move the image to the left
                  />
                </div>
              </div>
              {/* Bottom Items */}
              <div className="flex flex-col flex-grow justify-between p-4 pt-6 h800:pt-20">
                <div className="flex flex-col space-y-px -ml-2">
                  {/* Journey Text - Conditionally Rendered */}
                  {page !== 'header' && isUserLoggedIn && showPremiumJourney && (
                    <div className="py-3 h800:py-4 text-base font-bold font-sans mx-auto w-60 text-black border-b-2 border-bloc-color">
                      <div className="flex items-center cursor-pointer pl-7" onClick={() => navigate('/premiumjourney')}>
                        <img src={Journey} alt="Journey Icon" className="inline-block h-5 w-6 mr-6" />
                        Premium Journey
                      </div>
                    </div>
                  )}
                  {page !== 'header' && isUserLoggedIn && (

                    <div className="py-3 h800:py-4 text-base font-bold font-sans mx-auto w-60 text-black border-b-2 border-bloc-color">
                      <div className="flex items-center cursor-pointer pl-7" onClick={() => navigate('/myjourney')}>
                        <img src={Journey} alt="Journey Icon" className="inline-block h-5 w-6 mr-6" />
                        Standard Journey
                      </div>
                    </div>
                  )}
                  {/* Links */}
                  {commonLinks.map((link, index) => (
                    <Link
                      key={index}
                      to={link.to}
                      className="flex items-center py-3 h800:py-4 text-base font-bold font-sans text-black border-b-2 border-bloc-color mx-auto w-60"
                      onClick={() => setIsMenuOpen(false)} // Close menu on link click
                    >
                      <div className="flex items-center pl-7">
                        {link.label === 'Home' && (
                          <img src={homeIcon} alt="Home Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'Trainings' && (
                          <img src={Training} alt="Training Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'Counselling' && (
                          <img src={counseelingLogo} alt="counseelingLogo" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'Blog' && (
                          <img src={Message} alt="Message Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'Products' && (
                          <img src={Products} alt="Products Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label === 'About' && (
                          <img src={About} alt="About Icon" className="inline-block h-5 w-6 mr-6" />
                        )}
                        {link.label}
                      </div>
                    </Link>
                  ))}
                  <div className="py-3 h800:py-4 text-base font-bold font-sans mx-auto w-60 text-black border-b-2 border-bloc-color">
                    <a href="https://forms.office.com/pages/responsepage.aspx?id=7O6Egwz4PUysWxEbpZOPOw5hSyQXevpHgsHHAq7nfvVUNzNKSUVBTkNYVVRLQ1NRVTJTUzg3VktRTy4u&route=shorturl" target="_blank" rel="noopener noreferrer">

                      <div className="flex items-center pl-7">
                        <img src={feedback} alt="Journey Icon" className="inline-block h-5 w-6 mr-6" />
                        Feedback
                      </div>
                    </a>

                  </div>

                  {page !== 'header' && !isUserLoggedIn && (
                    <div className="flex flex-col flex-grow justify-between p-4 pt-10 ml-7">
                      <Link to="/login" className="rounded-full bg-custom-orange px-6 py-4 text-2xl font-medium font-sans text-white w-28 h-12 flex items-center justify-center">
                        Login
                      </Link>
                    </div>
                  )}
                </div>
                {page === 'header' && !isUserLoggedIn && (
                  <div className="flex flex-col flex-grow justify-between p-4 pt-10 ml-7">
                    <Link to="/login" className="rounded-full bg-custom-orange px-6 py-4 text-2xl font-medium font-sans text-white w-28 h-12 flex items-center justify-center">
                      Login
                    </Link>
                  </div>
                )}
                <div className="absolute h800:bottom-5 bottom-1">
                  {page !== 'header' && isUserLoggedIn && (
                    <div className="flex items-center cursor-pointer space-x-2 absolute bottom-0 ml-12" onClick={handleLogout}>
                      <img src={Logout} alt="Logout Icon" className="h-6 w-6" onClick={handleLogout} />
                      <span className="text-black text-lg font-bold font-sans">Logout</span>
                    </div>
                  )}
                </div>
              </div>
              {/* Fingerprint Image at the Bottom Left */}
              <div className="absolute bottom-0 right-0">
                <img src={Fingerprint} alt="Fingerprint Icon" className="h-auto w-20 h800:w-28" />
              </div>
            </div>
          </div>
          {/* Desktop Menu */}
          <div
            className={`fixed top-0 right-0 z-40 h-auto w-64 bg-menuebar shadow-lg transform transition-transform ${isDesktopMenuOpen ? '-translate-x-28' : 'translate-x-full'} hidden sm:block`}
            style={{ marginTop: '3.5rem' }}
          >
            <div className="relative flex flex-col h-full">
              <div className={`py-3 text-xl font-bold font-sans mx-auto w-44 text-black ${isUserLoggedIn ? 'border-b-2 border-bloc-color' : ''} py-2`}>
                {page === 'header' ? isUserLoggedIn && (
                  <a href="/" className="block">
                    <img src={Logo} alt="Journey Icon" className="h-24 w-56 pt-3" />

                  </a>

                ) : (
                  <div className="flex items-center justify-center pt-1 py-1">
                    {isUserLoggedIn && (
                      <span className=" text-black text-base font-medium leading-4 font-sans break-words overflow-wrap break-all">
                        {name}
                      </span>
                    )}
                  </div>
                )}
              </div>
              <div className="relative">
                {page !== 'header' && !isUserLoggedIn && (
                  <div className=" justify-between p-4 pt-3 ml-7">
                    <Link to="/login" className="rounded-full bg-custom-orange px-6 py-4 text-auto font-medium font-sans text-white w-28 h-10 flex items-center justify-center">
                      Login
                    </Link>
                  </div>
                )}
              </div>
              <div className="relative">
                {page !== 'header' && isUserLoggedIn && (
                  <div className="text-custom-blue flex justify-center text-sm font-bold font-sans ml-4">
                    Standard
                  </div>
                )}
              </div>
              <div className="flex flex-col flex-grow justify-between p-4 md:pt-4 lg:pt-2">
                <div className="flex flex-col space-y-px ">
                  {page !== 'header' && isUserLoggedIn && showPremiumJourney && (
                    <div className="py-2 text-base font-semibold font-sans mx-auto w-44 text-black border-b-2 border-bloc-color">
                      <div className="flex items-center cursor-pointer pl-2" onClick={() => navigate('/myjourney')}>
                        <img src={Journey} alt="Journey Icon" className="inline-block h-5 w-6 mr-2" />
                        Premium Journey
                      </div>

                    </div>

                  )}
                  {page !== 'header' && isUserLoggedIn && (
                    <div className="py-2 text-base font-semibold font-sans mx-auto w-44 text-black border-b-2 border-bloc-color">
                      <div className="flex items-center cursor-pointer pl-2 space-x-2" onClick={() => navigate('/myjourney')}>
                        <img src={Journey} alt="Journey Icon" className="h-5 w-6" />
                        <span className="whitespace-nowrap">Standard Journey</span>
                      </div>
                    </div>
                  )}

                  {page !== 'header' && isUserLoggedIn && (
                    <a href="https://forms.office.com/pages/responsepage.aspx?id=7O6Egwz4PUysWxEbpZOPOw5hSyQXevpHgsHHAq7nfvVUNzNKSUVBTkNYVVRLQ1NRVTJTUzg3VktRTy4u&route=shorturl" target="_blank" rel="noopener noreferrer">
                      <div className="py-2 text-base font-semibold font-sans mx-auto w-44 text-black border-b-2 border-bloc-color">
                        <div className="flex items-center pl-2">
                          <img src={feedback} alt="Journey Icon" className="inline-block h-5 w-6 mr-2" />
                          Feedback
                        </div>

                      </div>
                    </a>
                  )}

                  {page !== 'header' && isUserLoggedIn && (
                    <div className="py-2 text-base font-semibold font-sans mx-auto w-44 text-black">
                      <div className="flex items-center cursor-pointer pl-2" onClick={handleLogout}>
                        <img src={Logout} alt="Journey Icon" className="inline-block h-5 w-6 mr-2" />
                        Logout
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

interface CreateNavbarProps {
  page: string;
}

const CreateNavbar: React.FC<CreateNavbarProps> = ({ page }) => {
  const navbarBgColor = ['counselling', 'trainings', 'contact', 'about', 'privacypolicy', 'questionnaire', 'exploreopportunities'].includes(page) ? '' : 'bg-background';
  return <Navbar page={page} bgColor={navbarBgColor} />;
};

export default CreateNavbar;