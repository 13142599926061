import React, { useEffect, useState } from 'react';
import image from '../../assets/img/journey/road_web_sutter.svg';
import disableImage from '../../assets/img/journey/road_web_sutter_disabled.svg'
import mobileImage from '../../assets/img/journey/road_mobile_shutter.svg';
import disableMobileImage from '../../assets/img/journey/road_mobile_shutter_disabled.svg'
import CreateNavbar from '../Navbar/Navbar';
import GridImageSteaper from '../steapers/GridImageSteaper';
import {isMobile} from 'react-device-detect';

interface ShutterProps {
    shutter: boolean;
    setShutter: (shutter: boolean) => void;
    userName: string;
    setJourneyPart: (History: number) => void;
    disabledShutter: boolean;
}

export type CellToJourneyPart = { [key: number]: number };

const Shutter: React.FC<ShutterProps> = ({ shutter, setShutter, userName, setJourneyPart, disabledShutter }) => {
    const [isVisible, setIsVisible] = useState(true);

    const toggleShutter = () => {
        if (shutter) {
            setIsVisible(false);
        } else {
            setShutter(true);
            setIsVisible(true);
        }
    };

    const cellToJourneyPart: { web: CellToJourneyPart; mobile: CellToJourneyPart } = {
        web: {
            82: 0, 71: 0, 72: 0, 62: 0, 61: 0, 52: 0,
            50: 1, 60: 1, 70: 1, 80: 1,
            33: 2, 43: 2, 53: 2,
            37: 3, 47: 3,
            24: 4, 34: 4
        },
        mobile: {
            20: 0, 26: 0, 31: 0, 36: 0,
            25: 1, 30: 1, 35: 1,
            17: 2, 22: 2,
            14: 3, 19: 3,
            7: 4, 12: 4
        }
    };

    const handleCellClick = (cellNumber: number) => {
        const journeyPart = isMobile ? cellToJourneyPart.mobile[cellNumber] : cellToJourneyPart.web[cellNumber];
        if (journeyPart !== undefined && !disabledShutter) {
            setJourneyPart(journeyPart);
            toggleShutter();
        }
    };

    useEffect(() => {
        if (!isVisible && shutter) {
            setTimeout(() => setShutter(false), 500);
        }
    }, [isVisible, shutter, setShutter]);

    return (
        <div className={`flex transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <div className='flex flex-col h-dvh w-dvw md:justify-between'>
                <CreateNavbar page={'home'} />
                {!isMobile
                    ? (
                        <div className="flex overflow-hidden w-full justify-end">
                            <GridImageSteaper
                                src={disabledShutter ? disableImage : image}
                                rows={10} // Adjust the number of rows as necessary
                                cols={10} // Adjust the number of columns as necessary
                                onCellClick={handleCellClick}
                                containerClassName=""
                                imageClassName=""
                            />
                            <div className='w-2/5 p-10'>
                                <div className='container shadow-xl p-4 rounded-lg bg-white' style={{ boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.2)' }}>
                                    <div className='p-4'>
                                        {disabledShutter
                                            ? <div className='flex flex-col gap-4'>
                                                <div className='font-bold'> Thank you for submitting your answers!<br />
                                                    It may take upto 3 mins to create your standard career journey.</div>
                                                <div className='text-sm font-sans'><span className='font-bold text-cyan-600'>Standard Journey: </span>Your standard journey consists of a high-level roadmap of your career, guiding you towards your ultimate goal. As soon as the journey is created, the milestones on the road will be enabled. You can click on the milestones to view your standard journey.</div>
                                                <div className='text-sm font-sans'><span className='font-bold text-cyan-600'>Premium Journey: </span>You can access a more detailed, specific and actionable roadmap by subscribing to our premium journey feature. You can avail this feature by clicking on the premium journey button from your standard journey page.</div>
                                            </div>
                                            : <div>
                                                <div className='font-bold text-cyan-700 font-sans'>Congratulations {userName}!</div>
                                                <div> Your Career Journey is now created. Please click on the yearly milestones to review it. If you want a more detailed, specific and actionable roadmap, you can subscribe by clicking on the premium journey button on the next page. </div>
                                                <div className='flex justify-end italic'>Bon Voyage!</div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                    : (
                        <div className="flex flex-col h-full justify-between">
                            <div className='p-5'>
                                <div className='container shadow p-3 rounded-lg bg-white'>
                                    <div className=''>
                                    {disabledShutter
                                            ? <div className='text-xs font-sans'>
                                                <div className='font-bold'> Thank you for submitting your answers!<br />
                                                    It may take upto 3 mins to create your standard career journey.</div>
                                                <div className=''>You can also get a more detailed, specific and actionable roadmap by subscribing to our premium journey feature on the next page.</div>
                                                
                                            </div>
                                            : <div className=' flex flex-col gap-1   text-xs font-sans'>
                                                <div className='font-bold text-cyan-700 font-sans'>Congratulations {userName}!</div>
                                                <div> Your Career Journey is now created. Please click on the yearly milestones to review it. If you want a more detailed, specific and actionable roadmap, you can subscribe by clicking on the premium journey button on the next page. </div>
                                                <div className='flex justify-end italic'>Bon Voyage!</div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                            <GridImageSteaper
                                src={disabledShutter ? disableMobileImage : mobileImage}
                                rows={10} // Adjust the number of rows as necessary
                                cols={5} // Adjust the number of columns as necessary
                                onCellClick={handleCellClick}
                                containerClassName=""
                                imageClassName=""
                            />
                        </div>
                    )}
            </div>
        </div>
    );
};

export default Shutter;
