import { BackendApi } from "./apiConfig/ApiFormatter";



export const FetechUserProfile = async (): Promise<any[]> => {
    const apiUrl = BackendApi() + "getUserProfile";

    try {
        const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

        const response = await fetch(apiUrl, {
            headers: {
                'Authorization': `${idToken}` 
            }
        });

        if (!response.ok) {
            console.log('Error fetching user questionnaire');
        }

        const profileData = await response.json();
        
        localStorage.setItem('Name',profileData.response.name)
        return profileData.profileURL;
    } catch (error) {
        console.error('Error fetching user questionnaire:', error);
        throw error; // Rethrow error to handle it in the component
    }
};