import { BackendApi } from "./apiConfig/ApiFormatter";

type Fields = {
    id: string;
    label: string;
    type: string;
    name: string;
    required: boolean;
    options?: { value: string; label: string }[];
    validations?: { maxSelect?: Number | null };
};

export type Question = {
    shortName: string;
    subTitle: string;
    sectionId: number;
    fields: Fields[];
    title: string;
    description?: string;
};

type DraftData = {
    [key: string]: any;
};

type Section = {
    sectionId: number;
    title: string;
    questions: {
        id: string;
        question: string;
        reply: any;
    }[];
};

export const SectionWiseInsertion = async (
    currentStep: number,
    selectedFile:any| null,
    draftData: DraftData,
    questions: Question[],
    triggerJourney: boolean
): Promise<Response> => {
    const uploadUrl = BackendApi() + "updateUserQuestionnaire";
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage
    console.log(draftData);
    const data = {
        username: localStorage.getItem('Email'),
        questionnaireComplete: triggerJourney ? true : false, // Fixed ternary operation
        questionnaire: constructQuestionnaireSection(currentStep, draftData, questions)
    };

    const formDataObj = new FormData();
    formDataObj.append('file', selectedFile);
    formDataObj.append('data', JSON.stringify(data));


    try {
        const response = await fetch(uploadUrl, {
            method: 'PUT',
            headers: {
                'Authorization': `${idToken}`, // Added "Bearer" for token format
            },
            body: formDataObj
        });

        if (!response.ok) {
            console.log('Network response was not ok');
        }

        return response;
    } catch (error) {
        console.error('There was a problem with the fetch operation:', error);
        throw error;
    }
};

const constructQuestionnaireSection = (currentStep: number, draftData: DraftData, questions: Question[]): Section[] => {
    const section = questions[currentStep];
    return [{
        sectionId: currentStep + 1,
        title: section.title,
        questions: section.fields.map((question, idx) => {
            const reply = draftData[question.id];
            const questionLabel = question.label;
            const id = `${currentStep + 1}.${idx + 1}`;
            return {
                id: id,
                question: questionLabel,
                reply: reply,
            };
        })
    }];
};
