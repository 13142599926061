import React, { useRef, useEffect } from 'react';
interface handlerProps {
    onOutsideClick: () => void;
    children: React.ReactNode;
    targetSelector?: string;
}
const OutsideClickHandler = ({ onOutsideClick, children ,targetSelector }: handlerProps) => {
    const wrapperRef = useRef<HTMLDivElement | null>(null);


    useEffect(() => {
        function handleClickOutside(event: MouseEvent) {
            
      
        if (wrapperRef.current) {
            if (wrapperRef.current.contains(event.target as Node)) {
                const target = event.target as HTMLElement;
                if (target.tagName === 'DIV' &&
                    target.classList.contains('md:hidden') &&
                    target.classList.contains('fixed') &&
                    target.classList.contains('inset-0') &&
                    target.classList.contains('z-50') &&
                    target.classList.contains('bg-opacity-50') &&
                    target.classList.contains('bg-black')) {
                    console.log('Event target is a div with the specified classes');
                    onOutsideClick();
                } else {
                    console.log('Event target does not have the specified classes');
                    
                }
                
            }
        }
    }

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

    }, [onOutsideClick]);

    return <div ref={wrapperRef}>{children}</div>;
};

export default OutsideClickHandler;
