// Function to save a number to localStorage
const saveNumberToLocalStorage = (key: any, value: any): void => {
    localStorage.setItem(key, value.toString());
};

// Function to get a number from localStorage
const getNumberFromLocalStorage = (key: any): any => {
    const item = localStorage.getItem(key);
    return item !== null ? parseFloat(item) : null;
};

// Function to get the user data from localStorage
const getUserData = (username: any): any | null => {
    const userData = localStorage.getItem(username);
    if (userData) {
        try {
            return JSON.parse(userData);
        } catch (error) {
            console.error("Failed to parse JSON from localStorage", error);
            return null;
        }
    }
    return null;
};

// Function to store or update user data in localStorage
const storeUserData = (username: any, data: any): void => {
    const jsonString = JSON.stringify(data);
    localStorage.setItem(username, jsonString);
};

// Function to append or update section data for a user
const updateSectionData = (username: any, sectionID: any, sectionData: any): void => {
    const userData = getUserData(username) || {}; // Retrieve existing data or initialize empty object
    userData[sectionID] = sectionData; // Update or add section data
    storeUserData(username, userData); // Store updated data
};

// Function to retrieve section data for a user
const getSectionData = (username: any, sectionID: any): any | null => {
    const userData = getUserData(username);
    return userData ? userData[sectionID] : null;
};

// Function to remove section data for a user
const removeSectionData = (username: any, sectionID: any): void => {
    const userData = getUserData(username);
    if (userData) {
        delete userData[sectionID]; // Remove the section data
        storeUserData(username, userData); // Store the updated data
    }
};


// Function to remove all user data for a given username
const removeUserData = (username: any): void => {
    localStorage.removeItem(username);
};

// Function to check if a section ID's data is present for a user
const isSectionDataPresent = (username: any, sectionID: any): boolean => {
    const userData = getUserData(username);
    if (userData) {
        return userData.hasOwnProperty(sectionID);
    }
    return false;
};

// Exporting all relevant functions
export {
    saveNumberToLocalStorage,
    getNumberFromLocalStorage,
    getUserData,
    storeUserData,
    updateSectionData,
    getSectionData,
    removeSectionData,
    removeUserData,
    isSectionDataPresent
};
