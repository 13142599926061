import React, { useCallback, useEffect, useState } from 'react';
import CreateNavbar from '../components/Navbar/Navbar';
import PremiumSidebar from '../components/sidebar/PremiumSidebar';
import PremiumJourneyBOdy from '../components/PremiumJourneys/PremiumJourneyBody';
import '../components/PremiumJourneys/PremiumJourneyBody.css'

const PremiumJourney: React.FC = () => {
  const [premiumJourneyStatus, setPremiumJourneyStatus] = useState('publish');
  const [isResponsive, setIsResponsive] = useState<boolean>(false);
  const [activeQuarter, setActiveQuarter] = useState<number>(1);

  const handleResize = useCallback(() => {
    setIsResponsive(window.innerWidth < 768);
  }, []);

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [handleResize]);

  const handleSelectQuarter = (quarter: number) => {
    setActiveQuarter(quarter);
    const elementId = `quarter${quarter}`;
    const targetElement = document.getElementById(elementId);

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: 'smooth' });
    }
  };
  const handleScroll = () => {
    const sections = [1, 2]; // Assuming you have two quarters
    const scrollPosition = window.scrollY;
    const quarterOffsets = sections.map(quarter => ({
      id: `quarter${quarter}`,
      offsetTop: document.getElementById(`quarter${quarter}`)?.offsetTop || 0
    }));

    for (const { id, offsetTop } of quarterOffsets) {
      if (scrollPosition >= offsetTop - 100 && scrollPosition < offsetTop + 100) {
        setActiveQuarter(parseInt(id.replace('quarter', '')));
        break;
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div className="h-full">
      <CreateNavbar page='premiumJourney' />
      {premiumJourneyStatus === 'publish'
      ? <div className="flex flex-col sm:flex-row md:space-x-5 sm:px-5 lg:px-10 pt-20">
        <PremiumSidebar visible={!isResponsive} onSelectQuarter={handleSelectQuarter} activeQuarter={activeQuarter} />
        <PremiumJourneyBOdy /> 
      </div>
      : <>intrim page or waiting page body</>}
    </div>
  );
};

export default PremiumJourney;
