import React, { useState, useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import { RazorpayButton } from '../common/TrainingRazorPay';

const validCoupons: { [key: string]: number } = {
  'SKILLJOURNEY_10': 10,
  'SKILLJOURNEY_30': 30,
  'JOURNEY_35': 35,
  'MY_JOURNEY_40': 40,
  "JOURNEY_50":50,
  "S@ur@bh_Ref":68.75,
  "@$_SKILL_%//000111//@$_SKILL_%":99.99,
  "Journey_50":50,
};

const technologies = [
  { src: 'https://images.hive.blog/DQmdEwABtS4HwKUCYsjWeTSnd9ZDHYXRVoycLPsrMqhtT8g/selenium.png', alt: 'Selenium' },
  { src: 'https://static.javatpoint.com/core/images/java-logo1.png', alt: 'Java' },
  { src: 'https://static.javatpoint.com/tutorial/testng/images/testng-tutorial.png', alt: 'TestNG' },
  { src: 'https://upload.wikimedia.org/wikipedia/commons/5/52/Apache_Maven_logo.svg', alt: 'Maven' },
  { src: 'https://git-scm.com/images/logos/downloads/Git-Icon-1788C.png', alt: 'Git' },
  { src: 'https://bornsql.ca/wp-content/uploads/2021/06/azuresql.png', alt: 'SQL' },
  { src: 'https://img.utdstc.com/icon/3c7/fcf/3c7fcf4930fa9402c22cee35e03fe9fcf9e8e47c9381d6b9e6922d71ee2e067a:200', alt: 'Eclipse' },
];

interface DataItem {
  id: number;
  title: string;
  type: any;
  availableSeats: number;
  price: number;
  date: string;
  venue: string;
}

const dummyData: DataItem[] = [
  { id: 1, title: 'Selenium Basics to Advanced Hands-on Workshop', type: 'Training', availableSeats: 5, price: 16000, date: '2024-09-14', venue: 'Online' },
  { id: 2, title: 'Advanced JavaScript', type: 'Webinar', availableSeats: 15, price: 2000, date: 'TBD', venue: 'Online' },
  { id: 3, title: 'Certified Scrum Master', type: 'Certification', availableSeats: 10, price: 10000, date: 'TBD', venue: 'In-Person' },
  { id: 4, title: 'Networking Event', type: 'Event', availableSeats: 50, price: 0, date: 'TBD', venue: 'Conference Hall' },
  { id: 5, title: 'Software Engineering Internship', type: 'Software Internship', availableSeats: 5, price: 0, date: 'TBD', venue: 'Company Office' },
];

const TrainingsTemp: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const courseId = Number(id);
  const course = dummyData.find(item => item.id === courseId) || dummyData[0];

  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [mobileNumber, setMobileNumber] = useState<string>('');
  const [couponCode, setCouponCode] = useState<string>('');
  const [discount, setDiscount] = useState<number>(0);
  const [nameError, setNameError] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');
  const [mobileError, setMobileError] = useState<string>('');
  const [shouldRenderRazorpay, setShouldRenderRazorpay] = useState(false);

  const coursePrice = course.price;
  const formRef = useRef<HTMLFormElement>(null);

  useEffect(() => {
    const storedEmail = localStorage.getItem('Email');
    if (storedEmail) {
      setEmail(storedEmail);
    }
  }, []);

  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const code = e.target.value;
    setCouponCode(code);

    const percentage = validCoupons[code];
    setDiscount(percentage || 0);
  };

  const handleMobileNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setMobileNumber(value);

    const mobilePattern = /^[0-9]{10}$/;
    if (mobilePattern.test(value)) {
      setMobileError('');
    } else {
      setMobileError('Please enter a valid 10-digit mobile number');
    }
  };

  const validateForm = (): boolean => {
    let isValid = true;
    setNameError('');
    setEmailError('');
    setMobileError('');

    if (!name) {
      setNameError('Full Name is required');
      isValid = false;
    }

    if (!email) {
      setEmailError('Email is required');
      isValid = false;
    } else {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailPattern.test(email)) {
        setEmailError('Please enter a valid email address');
        isValid = false;
      }
    }

    if (!mobileNumber) {
      setMobileError('Mobile number is required');
      isValid = false;
    } else {
      const mobilePattern = /^[0-9]{10}$/;
      if (!mobilePattern.test(mobileNumber)) {
        setMobileError('Please enter a valid 10-digit mobile number');
        isValid = false;
      }
    }

    return isValid;
  };

  const handleButtonClick = (e: React.MouseEvent<HTMLButtonElement>) => {
   
    
    const conditionToPrevent =  validateForm();; // Replace this with your actual condition

    if (conditionToPrevent) {
      setShouldRenderRazorpay(true);
    }else{
      setShouldRenderRazorpay(false);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);
  const discountAmount = ((coursePrice * discount) / 100).toFixed(2);
  const discountedPrice = coursePrice - parseFloat(discountAmount);

  const gstAmount = (discountedPrice * 0.18).toFixed(2);
  const total = (discountedPrice + parseFloat(gstAmount)).toFixed(2);

  return (
    <>
      <CreateNavbar page='home' />
      <section className='pt-0'></section>

      <div className="bg-gray-100 min-h-screen p-6">
        {/* Technologies Carousel Section */}
        <section className="bg-white p-8 rounded-lg shadow-md mb-12 mx-auto lg:w-2/3 md:w-4/5 border-t-4 border-custom-orange">
          <div className="text-center mb-6">
            <h2 className="text-2xl font-semibold text-gray-800 mb-4">Technologies You'll Master</h2>
            <p className="text-gray-600">Our comprehensive course covers essential tools and technologies used in the industry</p>
            <p className="text-gray-600 mb-6">
              for more details <a href="/docs/Selenium Basics to Advanced SkillJourney Hands On Workshop.pdf" download className="text-blue-500 underline">click here</a>
            </p>
          </div>
          <Carousel
            responsive={{
              superLargeDesktop: { breakpoint: { max: 4000, min: 3000 }, items: 5 },
              desktop: { breakpoint: { max: 3000, min: 1024 }, items: 4 },
              tablet: { breakpoint: { max: 1024, min: 464 }, items: 3 },
              mobile: { breakpoint: { max: 464, min: 0 }, items: 2 },
            }}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={2000}
          >
            {technologies.map((tech, index) => (
              <div key={index} className="p-4 flex justify-center">
                <img src={tech.src} alt={tech.alt} className="h-24 w-auto object-contain" />
              </div>
            ))}
          </Carousel>
        </section>

        {/* Main Content Section */}
        <main className="container mx-auto flex flex-col lg:flex-row gap-6">
          {/* Your Details Section */}
          <section className="bg-white p-8 rounded-lg shadow-md flex-1 min-h-[300px] lg:w-1/2 border-t-4 border-custom-orange">
            <div className="border-b-2 border-gray-200 pb-4 mb-6">
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">Your Details</h2>
            </div>
            <form id="your-details-form" ref={formRef}>
              <label className="block mb-4">
                <span className="font-medium text-gray-700">Full Name:</span>
                <input
                  type="text"
                  value={name}
                  placeholder="Enter your full name"
                  required
                  onChange={(e) => setName(e.target.value)}
                  className={`mt-1 block w-full px-3 py-2 border ${nameError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange`}
                />
                {nameError && <p className="text-red-500 text-sm mt-1">{nameError}</p>}
              </label>

              <label className="block mb-4">
                <span className="font-medium text-gray-700">Email:</span>
                <input
                  type="email"
                  value={email}
                  placeholder="Enter your email"
                  required
                  onChange={(e) => setEmail(e.target.value)}
                  className={`mt-1 block w-full px-3 py-2 border ${emailError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange`}
                />
                {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
              </label>

              <label className="block mb-4">
                <span className="font-medium text-gray-700">Mobile Number:</span>
                <input
                  type="tel"
                  value={mobileNumber}
                  placeholder="Enter your mobile number"
                  required
                  onChange={handleMobileNumberChange}
                  className={`mt-1 block w-full px-3 py-2 border ${mobileError ? 'border-red-500' : 'border-gray-300'} rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange`}
                />
                {mobileError && <p className="text-red-500 text-sm mt-1">{mobileError}</p>}
              </label>

              <label className="block mb-4">
                <span className="font-medium text-gray-700">Coupon Code (optional):</span>
                <input
                  type="text"
                  value={couponCode}
                  placeholder="Enter coupon code (if any)"
                  onChange={handleCouponChange}
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-custom-orange focus:border-custom-orange"
                />
              </label>
            </form>
          </section>

          {/* Payment Summary Section */}
          <section className="bg-white p-8 rounded-lg shadow-md flex-1 min-h-[300px] lg:w-1/2 border-t-4 border-custom-orange">
            <div className="border-b-2 border-gray-200 pb-4 mb-6">
              <h2 className="text-2xl font-semibold text-gray-800 mb-2">Payment Summary</h2>
            </div>
            <div className="space-y-4">
              <div className="flex justify-between">
                <span className="font-medium text-gray-700">Course Fee:</span>
                <span className="font-medium text-gray-800">₹{coursePrice.toFixed(2)}</span>
              </div>
              {discount > 0 && (
                <>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Discount ({discount}%):</span>
                    <span className="font-medium text-red-500">- ₹{discountAmount}</span>
                  </div>
                  <div className="flex justify-between">
                    <span className="font-medium text-gray-700">Discounted Price:</span>
                    <span className="font-medium text-gray-800">₹{discountedPrice.toFixed(2)}</span>
                  </div>
                </>
              )}
              <div className="flex justify-between">
                <span className="font-medium text-gray-700">GST (18%):</span>
                <span className="font-medium text-gray-800">₹{gstAmount}</span>
              </div>
              <div className="flex justify-between border-t-2 border-gray-200 pt-4">
                <span className="font-semibold text-xl text-gray-800">Total Payable:</span>
                <span className="font-semibold text-xl text-gray-800">₹{total}</span>
              </div>
              <div className="flex py-2 justify-center">
                <button
                  type="submit"
                  onClick={handleButtonClick}
                  className=""
                >
                   {shouldRenderRazorpay ? (
                    <RazorpayButton
                      buttonText="Proceed to Pay"
                      amount={total}
                      course_type="Online"
                      name={name}
                      email={email}
                      phone={mobileNumber}
                      title={dummyData[courseId-1].title}
                    />
                    ) : (
                      <button
                      type="submit"
                      className="w-full py-3 px-4 bg-custom-orange text-white rounded-md shadow-md hover:bg-orange-700 focus:outline-none focus:ring-2 focus:ring-custom-orange mt-6"
                      >
                        Validate Information
                      </button>
                    )}
                </button>
              </div>
            </div>
          </section>
        </main>
      </div>
    </>
  );
};

export default TrainingsTemp;
