import React, { useCallback, useEffect, useRef } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';

interface ConvertJourneyProps {
    standardJourney: any;
    journeyPart: number;
    setJourneyPart: (History: number) => void;
}

const ConvertJourney: React.FC<ConvertJourneyProps> = ({ standardJourney, journeyPart, setJourneyPart }) => {
    const refs = useRef<any[]>([]);

    // --------code to change steaper location as per the scroll location but it breaks the steaper scroll logic and hangs the system -------

    // const handleIntersect = useCallback(
    //     (entries: IntersectionObserverEntry[]) => {
    //         entries.forEach((entry) => {
    //             if (entry.isIntersecting) {
    //                 const index = refs.current.indexOf(entry.target);
    //                 if (index !== -1 && index !== journeyPart) {
    //                     setJourneyPart(index);
    //                 }
    //             }
    //         });
    //     },
    //     [journeyPart, setJourneyPart]
    // );

    // useEffect(() => {
    //     const observer = new IntersectionObserver(handleIntersect, {
    //         threshold: 0.7, // Adjust the threshold as needed
    //     });

    //     refs.current.forEach((ref) => {
    //         if (ref) observer.observe(ref);
    //     });

    //     return () => {
    //         refs.current.forEach((ref) => {
    //             if (ref) observer.unobserve(ref);
    //         });
    //     };
    // }, [handleIntersect]);
    // ---------------------------------------------------------------------------------------------

    useEffect(() => {
        if (refs.current[journeyPart]) {
            setTimeout(() => {
                refs.current[journeyPart].scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 500);
        }
    }, [journeyPart, standardJourney]);

    return (
        <>
            {standardJourney.map((section: any, index: number) => (
                <div
                    key={index}
                    ref={(el) => (refs.current[index] = el)}
                    className="px-1 py-2"
                >
                    <div className="text-lg font-bold text-cyan-700">{section.title.replace(/^Title for /, '')}</div>
                    <p className="flex text-sm py-1 font-sans">
                        <span>
                            <div className="font-semibold text-base">Current Assessment: </div>
                            {section.assessment}
                        </span>
                    </p>
                    <p className="flex text-sm py-1 font-sans">
                        <span>
                            <div className="font-semibold text-base">Plan: </div>
                            <ul className="list-decimal list-inside">
                                {section.milestones.map((milestone: string, idx: number) => (
                                    <li key={idx} className="">
                                        {milestone}
                                    </li>
                                ))}
                            </ul>
                        </span>
                    </p>
                    <p className="flex text-sm py-1 font-sans">
                        <span>
                            <div className="font-semibold text-base">Actionables: </div>
                            {section.actionable.map((links: any, key: number) => (
                                <div key={key} className="flex flex-row gap-1">
                                    <div>{key + 1}.</div>
                                    <div>
                                        {links.description}{' '}
                                        {links.url && (
                                            <span className="text-blue-500 cursor-pointer text-sm">
                                                <a href={links.url} target="_blank" rel="noopener noreferrer">
                                                    Click here <LaunchIcon style={{ fontSize: '0.875rem' }} />
                                                </a>
                                            </span>
                                        )}
                                    </div>
                                </div>
                            ))}
                        </span>
                    </p>
                    <p className="flex text-sm py-1 font-sans">
                        <span>
                            <div className="font-semibold text-base">Potential Outcome: </div>
                            {section.potentialOutcome}
                        </span>
                    </p>
                </div>
            ))}
        </>
    );
};

export default ConvertJourney;
