import React from 'react';
import ReactDOM from 'react-dom/client';

import App from './App';
import './index.css';
import { GoogleOAuthProvider } from "@react-oauth/google";

ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
  <GoogleOAuthProvider clientId='696561118234-878rvcigr8loohv8r9bhua7foe49ljgi.apps.googleusercontent.com'>
    <React.StrictMode>
      <div style={{ height: '100%' }}>
        <App />
      </div>
    </React.StrictMode>
  </GoogleOAuthProvider>
);
