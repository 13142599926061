import React, { useState, useEffect } from 'react';
import loading from "../assets/img/loading.svg";
import CreateNavbar from '../components/Navbar/Navbar';
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { CreateUser } from '../api/CreateUser';
import Tooltip from '@mui/material/Tooltip';
import car from "../assets/img/car.png";
import useMediaQuery from '@mui/material/useMediaQuery';
import { SnackbarUi } from '../components/Snackbar/SnackbarUi'; // Import your Snackbar component

const Journey: React.FC = () => {
  const navigate = useNavigate();
  const [isViewJourneyDisabled, setIsViewJourneyDisabled] = useState(false);
  const [journeyExist, setJourneyExist] = useState(false);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [backendMessage, setBackendMessage] = useState('');

  useEffect(() => {
    const journeyExistsInStorage = localStorage.getItem('journeyExist') === 'true';
    setJourneyExist(journeyExistsInStorage);
    if (!journeyExistsInStorage) {
      setIsViewJourneyDisabled(true);
    }

    // Check for Snackbar state and message
    const message = localStorage.getItem("backendMessage");
    const snackbarState = localStorage.getItem("isSnackbarOpen");
    if (message) {
      setBackendMessage(message);
      if (snackbarState === "true") {
        setIsSnackbarOpen(true);
      }
      // Clear localStorage after retrieving the data
      localStorage.removeItem("backendMessage");
      localStorage.removeItem("isSnackbarOpen");
    }
  }, []);

  const ViewJourney = () => {
    if (!isViewJourneyDisabled) {
      CreateUser();
      navigate("/myjourney");
    }
  };

  return (
    <div className="h-screen">
      <CreateNavbar page='contact' />
      <div className="flex-1 flex flex-col justify-center items-center md:mt-6 mt-12">
        {journeyExist ? (
          <Box
            sx={{
              width: isMobile ? 200 : 250,
              height: isMobile ? 200 : 250,
              borderRadius: '50%',
              border: '10px solid #1976d2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <img src={loading} alt="Loading" className="w-32 h-28" />
          </Box>
        ) : (
          <Box position="relative" display="inline-flex">
            <CircularProgress
              size={isMobile ? 200 : 250}
              thickness={2}
              sx={{
                animationDuration: '5s',
              }}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={loading} alt="Loading" className="w-32 h-28" />
            </Box>
          </Box>
        )}
        <p className="mt-6 text-lg font-proxima font-semibold">Thank You For Submitting Your Answers</p>
        {journeyExist && !isViewJourneyDisabled ? (
          <p className="mt-2 md:text-base text-sm font-proxima text-center font-medium">
            Congratulations! Your Career Journey is now created.<br /> Please click on the button below to review it. BonVoyage!
          </p>
        ) : (
          <p className="mt-2 md:text-base text-sm font-proxima text-center font-medium">
            Your Career Journey creation is in progress.
            It usually <br />
            takes up to 10 mins for the journey to be generated.<br />
            Please check after some time.
          </p>
        )}
        <Tooltip title={isViewJourneyDisabled ? 'Your Journey is not yet created' : ''}>
          <button
            className={`flex items-center my-7 justify-between font-proxima px-4 py-2.5 text-base font-bold tracking-wider transition-colors duration-300 transform w-auto md:w-60 h-14 md:mx-4 focus:outline-none rounded-full shadow-homepage whitespace-nowrap ${isViewJourneyDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-sky-600 text-white'}`}
            onClick={ViewJourney}
            disabled={isViewJourneyDisabled}
          >
            <span className="flex-grow text-center">VIEW MY JOURNEY</span>
            <img src={car} alt="Car Logo" className="h-5 w-5 ml-2" />
          </button>
        </Tooltip>
      </div>
      <SnackbarUi
        isSnackbarOpen={isSnackbarOpen}
        setIsSnackbarOpen={setIsSnackbarOpen}
        backendMessage={backendMessage}
      />
    </div>
  );
};

export default Journey;
