"use client"
import React, { useState, forwardRef } from 'react';
import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import ActiveCircle from '../../assets/img/premiumJourney/active-circle.png'

interface PremiumSidebarProps {
    visible: boolean;
    onSelectQuarter: (quarter: number) => void;
    activeQuarter: number;
}
const PremiumSidebar = forwardRef<HTMLDivElement, PremiumSidebarProps>(({ visible, onSelectQuarter, activeQuarter }, ref) => {
    const [selectedYear, setSelectedYear] = useState<any>(1);
    const [selectedQuarter, setSelectedQuarter] = useState<number | null>(1);

    const years = ['1', '2', '3', '4', '5', '6+'];
    const quarters = [1, 2, 3, 4];
    const [myVariable, setMyVariable] = useState<number | null>(1);

    const updateVariable = (quarter: number) => {
        setMyVariable(quarter);
    };
    const handleYearClick = (year: any) => {
        setSelectedYear(year);
        setSelectedQuarter(null);
    };

    // const handleQuarterClick = (quarter: number) => {
    //     setSelectedQuarter(quarter);
    //     onSelectQuarter(quarter); // Call the function when quarter is selected
    // };

    let handleQuarterClick = (event: any, quarter: number) => {
        event.preventDefault();
        event.stopPropagation();
        updateVariable(quarter);
        setSelectedQuarter(quarter);
        onSelectQuarter(quarter); // Call the function when quarter is selected
      };

    if (!visible) return null;

    return (
        <div ref={ref} className='w-full sm:w-44 md:w-56 lg:w-64 xl:w-80 mt-5'>

            {/* <div className="flex fixed">
                <button className='hidden md:block'>
                    <ChevronLeftIcon className="w-4 h-4" />
                </button>
                <p className='text-lg font-normal pl-2 hidden md:block'>Back</p>
            </div> */}
            <div className="custom-sidebar-width w-52 fixed main-box-shadow rounded-lg custom-sidebar-height overflow-y-scroll">
                <div className="flex flex-col pt-7 pl-9 sm:pl-3 lg:pl-8 bg-white rounded-lg">
                    {years.map((year) => (
                        <div key={year} className="relative">
                            <div
                                onClick={() => handleYearClick(year)}
                                className={`flex items-center cursor-pointer  ${selectedYear == year ? 'text-gray-500 mb-2' : 'text-gray-500 mb-7'
                                    }`}
                            >
                                <div
                                    className={`w-6 h-6 rounded-full border border-slate-100 mr-2 ${selectedYear == year ? '' : 'border-slate-100'
                                        }`}
                                >
                                    {selectedYear == year && (
                                        <img src={ActiveCircle} alt="active" />
                                    )}
                                </div>
                                <span className="font-medium text-base">YEAR {year}</span>
                            </div>
                            {year <= years[years.length - 1] && (
                                <div className="absolute left-2.5 top-7 bottom-1.5 w-px border-dashed border-l-2 "></div>
                            )}
                            {selectedYear == year && (
                                <div className="ml-6 relative mt-2 mb-3">
                                    {quarters.map((quarter) => (
                                        <div
                                            key={quarter}
                                            onClick={(e) => handleQuarterClick(e,quarter)}
                                            className={`flex items-center cursor-pointer font-semibold text-sm ${myVariable === quarter ? 'text-orange-500 font-medium' : 'text-gray-500'
                                                }`}
                                        >
                                            <div
                                                className={`arrow right ${myVariable === quarter ? 'border-orange-500' : 'border-gray-500'}`}
                                            ></div>

                                            <span>Quarter {quarter}</span>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
});

export default PremiumSidebar;
