import React, { useState, useEffect, useRef } from 'react';
import premium from "../assets/img/road.svg";
import CreateNavbar from '../components/Navbar/Navbar';
import { FetchPremiumJourney, FetchUserName } from "../api/FetchHtmlJourney";
import { PremiumJourneyStatusCheck } from "../api/JourneyStatusCheck";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { storeUserData } from "../components/common/StorageUtil";
import { CreateUser } from '../api/CreateUser';
import Tooltip from '@mui/material/Tooltip';
import car from "../assets/img/car.png";
import useMediaQuery from '@mui/material/useMediaQuery';
import { SnackbarUi } from '../components/Snackbar/SnackbarUi'; // Import your Snackbar component
import { isUserTokenValid } from '../components/common/ValidateToken';
const PremiumJourneyLoading: React.FC = () => {
  const navigate = useNavigate();
  const [isViewJourneyDisabled, setIsViewJourneyDisabled] = useState(false);
  const [journeyExist, setJourneyExist] = useState(false);
  const intervalRef = useRef<NodeJS.Timeout | null>(null);
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [disabledShutter, setDisabledShutter] = useState(true);
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [backendMessage, setBackendMessage] = useState('');
  const [loading, setLoading] = useState<boolean>(true); // Loading state

  useEffect(() => {
    console.log("Component Mounted or Updated");

    const initialize = async () => {
      const isValid = await isUserTokenValid();
      if (!isValid) {
        navigate('/');
        return;
      }
      const journeyExistsInStorage = sessionStorage.getItem('journeyExist') === 'true';
      setJourneyExist(journeyExistsInStorage);
      setIsViewJourneyDisabled(!journeyExistsInStorage);

      if (!journeyExistsInStorage) {
        startPolling();
      } else {
        await fetchJourneyData(); // Fetch immediately if already exists
      }
    };

    const fetchJourneyData = async () => {
      const journeyCheck = await PremiumJourneyStatusCheck();
      if (journeyCheck && ['completed', 'failed'].includes(journeyCheck.journey_status)) {
        stopPolling();
        setLoading(false);
        if (journeyCheck.journey_status === 'completed') {
          const journeyDetails = await FetchPremiumJourney();
          storeUserData('journeyExist', true);
          setIsViewJourneyDisabled(false); // Enable the button
        } else {
          setIsSnackbarOpen(true);
          setBackendMessage("Failed to complete the journey.");
          setIsViewJourneyDisabled(true); // Keep the button disabled
        }
      } else {
        console.log("Continuing to poll as journey status is neither completed nor failed.");
      }
    };

    const startPolling = () => {
      stopPolling();  // Ensure no previous intervals are running
      intervalRef.current = setInterval(fetchJourneyData, 5000);
      console.log("Polling started");
    };

    const stopPolling = () => {
      if (intervalRef.current) {
        console.log("Stopping polling");
        clearInterval(intervalRef.current);
        intervalRef.current = null;
      }
    };

    initialize();

    return () => {
      console.log("Component Unmounting");
      stopPolling();  // Clear interval on component unmount
    };
  }, []);

  const ViewJourney = () => {
    if (!isViewJourneyDisabled) {
      CreateUser();
      navigate("/premiumjourney");
    }
  };
  return (
    <div className="h-screen">
      <CreateNavbar page='contact' />
      <div className="flex-1 flex flex-col justify-center items-center md:mt-6 mt-12">
        {journeyExist ? (
          <Box
            sx={{
              width: isMobile ? 200 : 250,
              height: isMobile ? 200 : 250,
              borderRadius: '50%',
              border: '10px solid #1976d2',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              position: 'relative',
            }}
          >
            <img src={premium} alt="Loading" className="w-32 h-28" />
          </Box>
        ) : (
          <Box position="relative" display="inline-flex">
            <CircularProgress
              size={isMobile ? 200 : 300}
              thickness={2}
              sx={{
                width: isMobile ? 200 : 250,
                height: isMobile ? 200 : 250,
                animationDuration: '5s',
              }}
            />
            <Box
              top={0}
              left={0}
              bottom={0}
              right={0}
              position="absolute"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              <img src={premium} alt="Loading" className="md:w-64 md:h-44 w-52 h-28 -mt-6 md:-mt-10" />
            </Box>
          </Box>
        )}
        <p className="mt-8 text-lg font-proxima font-semibold">Thank You For Subscribing to Skill Joureny</p>
        {journeyExist && !isViewJourneyDisabled ? (
          <p className="mt-6 md:text-base text-sm font-proxima text-center font-medium">
            Congratulations! Your Career Premium Journey is now created.<br /> Please click on the button below to review it. BonVoyage!
          </p>
        ) : (
          <p className="mt-6 md:text-base text-sm font-proxima text-center font-medium">
            Your Premium Career Journey creation is in progress. <span className="hidden md:inline"><br /></span>
            It usually takes up to 10 mins for the journey to be generated. <span className="hidden md:inline"><br /></span>
            Please check after some time.
          </p>

        )}
        <Tooltip title={isViewJourneyDisabled ? 'Your Journey is not yet created' : ''}>
          <button
            className={`flex items-center my-7 justify-between font-proxima px-4 py-2.5 text-base font-bold tracking-wider transition-colors duration-300 transform w-auto md:w-72 h-14 md:mx-4 focus:outline-none rounded-full shadow-homepage whitespace-nowrap ${isViewJourneyDisabled ? 'bg-gray-400 text-gray-200 cursor-not-allowed' : 'bg-sky-600 text-white'}`}
            onClick={ViewJourney}
            disabled={isViewJourneyDisabled}
          >
            <span className="flex-grow text-center">VIEW MY PREMIUM JOURNEY</span>

          </button>
        </Tooltip>
      </div>
      <SnackbarUi
        isSnackbarOpen={isSnackbarOpen}
        setIsSnackbarOpen={setIsSnackbarOpen}
        backendMessage={backendMessage}
      />
    </div>
  );
};

export default PremiumJourneyLoading;
