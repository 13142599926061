import React, { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar';
import ClarityScript from '../common/Clarity';

interface DataItem {
  id: number;
  title: string;
  type:any;
  availableSeats: number;
  price: number;
  date: string;
  venue: string;
}



const dummyData: DataItem[] = [
  { id: 1, title: 'Selenium Basics to Advanced Hands-on Workshop', type: 'Training', availableSeats: 5, price: 20000, date: '2024-09-14', venue: 'Online' },
  { id: 2, title: 'Advanced JavaScript', type: 'Webinar', availableSeats: 15, price: 2000, date: 'TBD', venue: 'Online' },
  { id: 3, title: 'Certified Scrum Master', type: 'Certification', availableSeats: 10, price: 10000, date: 'TBD', venue: 'In-Person' },
  { id: 4, title: 'Networking Event', type: 'Event', availableSeats: 50, price: 0, date: 'TBD', venue: 'Conference Hall' },
  { id: 5, title: 'Software Engineering Internship', type: 'Software Internship', availableSeats: 5, price: 0, date: 'TBD', venue: 'Company Office' },
];

const TrainingPage: React.FC = () => {
  const [filter, setFilter] = useState<string>('All');
  const navigate = useNavigate ();
  
  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  const filteredData = filter === 'All' ? dummyData : dummyData.filter(item => item.type === filter);

  const handleBoxClick = (id: number) => {
    navigate(`/trainings/${id}`);
  };

  return (
    <>
      <ClarityScript />
      <CreateNavbar page='home' />
      <section className='pt-0'></section>

      <div className="bg-gray-100 min-h-screen p-6">
        <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">Available Courses and Events</h1>

        {/*<div className="flex justify-center space-x-4 mb-8">
          {['All', 'Training', 'Webinar', 'Certification', 'Event', 'Internship Program'].map((filterType) => (
            <button
              key={filterType}
              onClick={() => setFilter(filterType)}
              className={`px-6 py-2 border rounded-lg transition duration-300 ease-in-out ${
                filter === filterType
                  ? 'bg-custom-orange text-white border-custom-orange'
                  : 'bg-white text-gray-700 border-gray-300 hover:bg-gray-100'
              }`}
            >
              {filterType}
            </button>
          ))}
        </div>*/}

        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-6">
          {filteredData.map((item) => (
            <div
              key={item.id}
              onClick={item.id === 1 ? () => handleBoxClick(item.id) : undefined}
              className="relative bg-white p-6 rounded-lg shadow-lg border border-gray-200 cursor-pointer hover:shadow-xl transition-transform transform hover:scale-105"
            >
              <div className="absolute top-0 right-0 bg-custom-orange text-white px-4 py-1 text-xs uppercase font-semibold rounded-bl-lg">
                {item.type} - {item.date}
              </div>
              <h3 className="text-2xl font-semibold mb-2 text-gray-800">{item.title}</h3>
              <p className="text-gray-600 mb-1"><strong>Available Seats:</strong> {item.availableSeats}</p>
              <p className="text-gray-600 mb-1 hidden"><strong>Price:</strong> ₹{item.price}</p>
              <p className="text-gray-600 mb-1"><strong>Date:</strong> {item.date}</p>
              <p className="text-gray-600"><strong>Venue:</strong> {item.venue}</p>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default TrainingPage;
