import React from "react";
import { Snackbar, SnackbarContent } from "@mui/material";
import { messagesColor, getStatusText } from "./uimessage";
import SuccessImageUrl from "../../assets/img/Success.png";
import WarningImageUrl from "../../assets/img/Warning.png";
import SuccessCloseImageUrl from "../../assets/img/Sucessclose.png";
import WarningCloseImageUrl from "../../assets/img/Warningclose.png";

interface SnackbarUiProps {
  isSnackbarOpen: boolean;
  setIsSnackbarOpen: (open: boolean) => void;
  backendMessage: string;
  onCloseCallback?: () => void; // Add this prop
}

export const SnackbarUi: React.FC<SnackbarUiProps> = ({
  isSnackbarOpen,
  setIsSnackbarOpen,
  backendMessage,
  onCloseCallback,
}) => {
  const statusText = getStatusText(backendMessage);
  const backgroundColor = messagesColor(backendMessage);
  const handleClose = () => {
    setIsSnackbarOpen(false);
    if (onCloseCallback) { // Invoke the callback if provided
      onCloseCallback();
    }
  };

  const formatMessage = (message: string) => {
    return message.split(/""/).filter(Boolean).map((paragraph, index) => {
      if (paragraph.includes("contact us.")) {
        return (
          <div key={index}>
            {paragraph.split("contact us.").map((part, i) => (
              <React.Fragment key={i}>
                {part}
                {i === 0 ? (
                  <a href="/contactUs" style={{ color: '#0778b6', textDecoration: 'underline' }}>
                    contact us.
                  </a>
                ) : null}
              </React.Fragment>
            ))}
          </div>
        );
      }
      return <div key={index}>{paragraph}</div>;
    });
  };

  return (
    <Snackbar
      open={isSnackbarOpen}
      onClose={handleClose}
      anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
    >
      <SnackbarContent
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          backgroundColor: backgroundColor,
          borderRadius: "22px",
          fontWeight: "normal",
          color: "black",
          padding: "10px",
          position: "relative",
        }}
        message={
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", flex: 1 }}>
              {statusText === "Success" && (
                <img
                  src={SuccessImageUrl}
                  alt="Success"
                  style={{ width: "24px", height: "24px", marginRight: "16px" }}
                />
              )}
              {statusText === "Warning" && (
                <img
                  src={WarningImageUrl}
                  alt="Warning"
                  style={{ width: "24px", height: "24px", marginRight: "16px" }}
                />
              )}
              <div style={{ display: "flex", flexDirection: "column", flex: 1, overflow: "hidden" }}>
                {statusText && (
                  <div
                    style={{
                      fontWeight: "bold",
                      textAlign: "left",
                      overflow: "hidden",
                      whiteSpace: "nowrap",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {statusText}
                  </div>
                )}
                <div style={{ overflow: "hidden", textAlign: "left", marginRight: "20px" }}>
                  {formatMessage(backendMessage)}
                </div>
              </div>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              {statusText && (
                <img
                  src={statusText === "Success" ? SuccessCloseImageUrl : WarningCloseImageUrl}
                  alt="Close"
                  style={{ width: "16px", height: "14px", cursor: "pointer" }}
                  onClick={handleClose}
                />
              )}
            </div>
          </div>
        }
      />
    </Snackbar>
  );
};
