import { useRef } from "react";
import ClearTwoToneIcon from '@mui/icons-material/ClearTwoTone';


export const ResumeSelect: React.FC<any> = ({ selectedFile, setSelectedFile }) => {
    const hiddenFileInput = useRef<HTMLInputElement>(null);

    const handleClick = (event: any) => {
        event.preventDefault();
        hiddenFileInput.current?.click();
    };

    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        if (fileUploaded && fileUploaded.type !== "application/pdf") {
            alert("You are trying to upload an unsupported file format. Please upload PDF files only.")
            setSelectedFile(null);
        } else {
            setSelectedFile(fileUploaded);
        }
    };

    return (
        <div className="py-10">
            <div className="flex flex-row gap-2 px-10 py-5 bg-file-upload-blue-blur w-full rounded-xl border-1 border-file-upload-outer">
                <div className="flex justify-center items-center">
                    <button className="p-2 text-back-prev-text border-2 border-file-upload h-11 w-32 rounded-md text-sm sm:text-base" onClick={handleClick}>
                        BROWSE
                    </button>
                    <input
                        type="file"
                        onChange={handleChange}
                        ref={hiddenFileInput}
                        style={{ display: 'none' }} // Make the file input element invisible
                    />
                </div>
                <div>
                    <div className="font-medium text-sm sm:text-lg">
                        {selectedFile !== null && selectedFile !== undefined ? 'Selected File: ' : 'Select Your Resume to Upload'}
                    </div>
                    {selectedFile !== null && selectedFile !== undefined
                        ? <div className="flex gap-3">
                            <div className="text-inherit">{selectedFile.name}</div>
                            <div onClick={() => setSelectedFile(null)}>
                                <ClearTwoToneIcon style={{ fontSize: 18 }} />
                            </div>
                        </div>
                        : <div className="block text-sm sm:text-base text-description-text w-fit">
                            Supported File Format: PDF
                        </div>}
                </div>
            </div>
        </div>
    );
}

