import React, { useEffect, useLayoutEffect, useState } from 'react'
import Footer from '../components/footer/Footer'
import ClarityScript from '../components/common/Clarity';


import Meet from '../components/counselors/Meet';
import Carousel from 'react-multi-carousel';
import Rating from '@mui/material/Rating'; // Import the Rating component from Material-UI

import Transform from '../components/careerPath/Transform';
import Passion from '../components/careerSolutions/Passion';
import Header from '../components/Header/header';
import { useNavigate } from 'react-router-dom';
import { isUserTokenValid } from '../components/common/ValidateToken';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { get_counsellor } from '../api/Counsellor';
import CounselorCarousel from '../components/common/counsellorCards';





export default function Landing() {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);

  useLayoutEffect(() => {
    const initialize = async () => {
      setLoading(true);
      const isValid = await isUserTokenValid();
      if (isValid) {
        navigate('/home');

      }
      setLoading(false);
    };
    initialize();
  }, [navigate]);

  

  if (loading) {
    return <LoadingSpinner />; // Show spinner while loading
  }
  return (
    <>
      <ClarityScript />
      <div className="flex overflow-y-auto flex-col">
        <Header />
        <Passion />
        <div className='md:container md:mx-auto'>
          <Transform />
        </div>
        <CounselorCarousel />
        {/* <Meet /> */}
        <Footer />
      </div>
    </>
  )
}
