import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import { FetchJourney, FetchUserName } from "../api/FetchHtmlJourney";
import { isUserTokenValid } from "../components/common/ValidateToken";
import { JourneyStatusCheck } from "../api/JourneyStatusCheck";
import { storeUserData } from "../components/common/StorageUtil";
import Shutter from "../components/journeys/Shutter";
import BasicJourneyBody from "../components/journeys/BasicJourneyBody";
import { SnackbarUi } from "../components/Snackbar/SnackbarUi";
import LoadingSpinner from "../components/common/LoadingSpinner";

export default function BasicJourney() {
    const [shutter, setShutter] = useState(true);
    const [userName, setUserName] = useState<string>('');
    const [journeyPart, setJourneyPart] = useState<number>(0);
    const [standardJourney, setStandardJourney] = useState<any>({});
    const [disabledShutter, setDisabledShutter] = useState(true);
    const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);

    const navigate = useNavigate();
    const intervalRef = useRef<NodeJS.Timeout | null>(null);
    const [loading, setLoading] = useState<boolean>(true); // Loading state
    const location = useLocation();
    const { redirect } = location.state || "";

    const fetchUserName = async () => {
        if (await isUserTokenValid()) {
            setUserName(await FetchUserName());
        } else {
            navigate('/');
        }
    };

    const fetchJourney = async () => {
        if (!(await isUserTokenValid())) {
            navigate('/');
            return;
        }

        const journeyCheck = await JourneyStatusCheck();

        if (journeyCheck?.journey_status !== 'initiated') {
            const journeyData = await FetchJourney();
            setStandardJourney(journeyData);
        }

        if (['completed', 'failed'].includes(journeyCheck?.journey_status)) {
            if (intervalRef.current) clearInterval(intervalRef.current);

            if (journeyCheck?.journey_status === 'completed') {
                setDisabledShutter(false);
                setLoading(false);
            } else if (journeyCheck?.journey_status === 'failed') {
                setIsSnackbarOpen(true);
            }
        } else {
            storeUserData('journeyExist', true);
        }
    };

    useEffect(() => {
        fetchUserName();
        fetchJourney();
        intervalRef.current = setInterval(fetchJourney, 5000);

        return () => {
            if (intervalRef.current) clearInterval(intervalRef.current);
        };
    }, []);
    useEffect(() => {
        if (redirect === "Questionnaire-to-MyJourney") {
            setLoading(false);
            window.history.replaceState({}, "");
        }
    }, [redirect]);

    if (loading) { return <LoadingSpinner />; }

    return (
        <>
            {shutter ? (
                <Shutter
                    shutter={shutter}
                    setShutter={setShutter}
                    userName={userName}
                    setJourneyPart={setJourneyPart}
                    disabledShutter={disabledShutter}
                />
            ) : (
                <BasicJourneyBody
                    userName={userName}
                    journeyPart={journeyPart}
                    setShutter={setShutter}
                    setJourneyPart={setJourneyPart}
                    standardJourney={standardJourney.career_journey}
                />
            )}
            <SnackbarUi
                isSnackbarOpen={isSnackbarOpen}
                setIsSnackbarOpen={setIsSnackbarOpen}
                backendMessage="We’re sorry, your journey creation has failed for some technical reason. We are working on it, please retry creating your journey or contact us."
            />
        </>
    );
}
