import React from 'react';
import Logo from '../../assets/img/skillJourneyMainLogo.svg';

interface NavbarProps {
  bgColor?: string;
}

const Navbar: React.FC<NavbarProps> = ({ bgColor }) => {
  return (
    <nav className={`relative ${bgColor || 'bg-white'}`}>
      <div className="max-w-full px-4 md:mx-16">
        <div className="relative flex h-16 items-center justify-between">
          {/* Logo */}
          <div className="flex-shrink-0">
          <a href="/" className="block">
            <img src={Logo} alt="Logo" className="w-[140px] md:w-[160px] h-20" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

interface LoginnavProps {
  bgColor?: string;
}

const Loginnav: React.FC<LoginnavProps> = ({ bgColor }) => {
  return <Navbar bgColor={bgColor} />;
};

export default Loginnav;
