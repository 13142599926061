import { FieldErrors, FieldValues, UseFormRegister } from "react-hook-form";
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import { useState, useEffect, useRef } from "react";
import { Tooltip } from "@mui/material";

interface InputProps {
  disabled?: boolean;
  required?: boolean;
  field?: any;
  register: UseFormRegister<FieldValues>;
  errors: FieldErrors;
  value?: string;
  onChange?: (value: string) => void;
}

const Input: React.FC<InputProps> = ({ disabled, register, required, field, errors, value = '', onChange }) => {
  const [open, setOpen] = useState(false);
  const tooltipRef = useRef<HTMLDivElement>(null);
  const [localError, setLocalError] = useState<string | null>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  } 
  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

   // Handle input value and validation
if (field.label === "Contact Number") {
  const validCharacters = /^[0-9]*$/;
  const maxLength = 10;

  let errorMessage = "";

  // Check if the input contains only valid characters (numbers)
  if (!validCharacters.test(inputValue)) {
    errorMessage = "Contact number must contain only numbers.";
  }
  // Check if the input exceeds the maximum length
  else if (inputValue.length > maxLength) {
    errorMessage = "Contact number must be up to 10 digits.";
  }

  // Set the error message if there's an issue, otherwise clear the error
  if (errorMessage) {
    setLocalError(errorMessage);
    // Clear the error after 3 seconds
    setTimeout(() => {
      setLocalError(null);
    }, 3000);
  }  else {
    setLocalError(null);
    onChange && onChange(inputValue);
  }
}

  else if (field.label === "Secondary Email") {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    
      // Always call onChange to update the input value
      onChange && onChange(inputValue);
    
      if (!emailPattern.test(inputValue)) {
        setLocalError("Enter a valid email address.");
      } else {
        setLocalError(null);
      }
    }
    else if (field.label === "Full Name") {
      const namePattern = /^[a-zA-Z\s]*$/; // Detects only alphabetic characters and spaces
      const maxLength = 49;
      
      let errorMessage = "";
    
      // Check if the input contains anything other than letters or spaces
      if (!namePattern.test(inputValue)) {
        errorMessage = "Full name must only contain alphabetic characters and spaces.";
      }
      // Check if the input exceeds the maximum length
      else if (inputValue.length > maxLength) {
        errorMessage = "Full name must be up to 50 characters.";
      }
    
      // Set the error message if there's an issue, otherwise clear the error
      if (errorMessage) {
        setLocalError(errorMessage);
    
        // Clear the error after 3 seconds
        setTimeout(() => {
          setLocalError(null);
        }, 3000);
      } else {
        setLocalError(null);
        onChange && onChange(inputValue);
      }
    }
    
    else {
      onChange && onChange(inputValue);
    }
    
  };

  // Extracting the error message
  const errorMessage = errors[field.id]?.message as string | undefined;

  return (
    <div className="w-full relative">
      <label className="block text-base text-description-text">
        {field.label}
        {field.required && <sup className="text-red-600 font-bold text-base">* </sup>}
        {field?.tooltip && (
          <div ref={tooltipRef} className="inline-block">
            <Tooltip
              PopperProps={{
                disablePortal: true,
              }}
              onClose={() => setOpen(false)}
              open={open}
              disableFocusListener
              disableHoverListener
              disableTouchListener
              title={field.tooltip}
              onClick={() => setOpen(true)}
            >
              <InfoTwoToneIcon sx={{ color: '#0778B6', height: '3vh', width: '3vw', paddingBottom: '2px' }} />
            </Tooltip>
          </div>
        )}
      </label>
      <input
        id={field.id}
        disabled={disabled}
        {...register(field.id, {
          required,
          validate: (value) => {
            if (field.label === "Contact Number") {
              return /^[0-9+\- )(]*$/.test(value) && value.length <= 20
                ? true
                : "Contact number must contain only numbers, +, -, (), and spaces, and be up to 20 characters long.";
            } else if (field.label === "Secondary Email") {
              return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) || value === ''
                ? true
                : "Enter a valid email address.";
            } else {
              return true;
            }
          },
        })}
        placeholder=""
        type={field.type}
        value={value}
        onChange={handleInputChange}
        className="border-b-2 border-zinc-200 w-full pt-4 focus:outline-none focus:shadow-outline font-medium text-xl"
      />
      {/* Conditionally rendering the error message */}
      {(errorMessage || localError) && (
        <p className="text-red-600 text-sm">{localError || errorMessage}</p>
      )}
    </div>
  );
};

export default Input;
