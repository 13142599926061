import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../assets/img/skilljourneywhite.png';
import Facebook from '../../assets/img/policies/facebook.svg';
import Whatsapp from '../../assets/img/policies/whatsapp.svg';
import Instagram from '../../assets/img/policies/instagram.svg';
import Linkdin from '../../assets/img/policies/linkedin.svg';

const Footer: React.FC = () => {
  const [showPolicies, setShowPolicies] = useState(false);
  const [showCompany, setShowCompany] = useState(false);
  const [showContact, setShowContact] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      const isNowMobile = window.innerWidth < 768;
      setIsMobile(isNowMobile);
      if (!isNowMobile) {
        setShowPolicies(true);
        setShowCompany(true);
        setShowContact(true);
      } else {
        setShowPolicies(false);
        setShowCompany(false);
        setShowContact(false);
      }
    };

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <footer className="bg-primary text-white w-full">
      <div className="max-w-full mx-auto px-4 sm:px-4 lg:px-8 py-8">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          <div className="flex flex-col items-center md:items-start mt-2 md:mt-12">
            <div className="flex items-center space-x-2">
              <img src={Logo} className="w-32 md:w-40" alt="logo" />
              <div className="border-l border-indigo-300 pl-2 text-xs sm:text-sm">
                From Learning <br /> To Leading
              </div>
            </div>
            <div className="flex mt-4 mx-5 lg:mt-6 space-x-4 lg:space-x-8">
              <a href="https://www.facebook.com/profile.php?id=61564961467705" target="_blank" rel="noopener noreferrer" className="flex items-center">
                <img src={Facebook} className="w-5 h-5 sm:w-6 sm:h-6" alt="Facebook" />
              </a>
              <a href="https://wa.me/+919699185758" target="_blank" rel="noopener noreferrer" className="flex items-center">
                <img src={Whatsapp} className="w-5 h-5 sm:w-6 sm:h-6" alt="WhatsApp" />
              </a>
              <a href="https://www.instagram.com/skilljourney.ai?igsh=MXd4MGhyMDVkc2RpaQ%3D%3D&utm_source=qr" target="_blank" rel="noopener noreferrer" className="flex items-center">
                <img src={Instagram} className="w-5 h-5 sm:w-6 sm:h-6" alt="Instagram" />
              </a>
              <a href="https://www.linkedin.com/company/skilljourney/" target="_blank" rel="noopener noreferrer" className="flex items-center">
                <img src={Linkdin} className="w-5 h-5 sm:w-6 sm:h-6" alt="LinkedIn" />
              </a>
            </div>
          </div>
          <div className="relative">
            <h1
              className="text-sm sm:text-base font-bold cursor-pointer flex items-center"
              onClick={() => isMobile && setShowPolicies(!showPolicies)}
            >
              <span>Policies</span>
              {isMobile && (
                <span className={`transform ${showPolicies ? 'rotate-180' : 'rotate-0'} transition-transform duration-300 ml-2`}>
                  ▼
                </span>
              )}
            </h1>
            {(showPolicies || !isMobile) && (
              <div className="space-y-1 mt-2">
                <Link to="/contactUs" className="block text-xs sm:text-sm inline-block">Contact Us</Link><br />
                <Link to="/privacypolicy" className="block text-xs sm:text-sm inline-block">Privacy Policy</Link><br />
                <Link to="/terms&conditions" className="block text-xs sm:text-sm inline-block">Terms & Conditions</Link><br />
                <Link to="/cancellation&refund" className="block text-xs sm:text-sm inline-block">Cancellation & Refund</Link><br />
                <Link to="/shipping" className="block text-xs sm:text-sm inline-block">Shipping & Delivery</Link><br />
              </div>
            )}
          </div>

          <div className="relative">
            <h1
              className="text-sm sm:text-base font-bold cursor-pointer flex items-center"
              onClick={() => isMobile && setShowCompany(!showCompany)}
            >
              <span>Company</span>
              {isMobile && (
                <span className={`transform ${showCompany ? 'rotate-180' : 'rotate-0'} transition-transform duration-300 ml-2`}>
                  ▼
                </span>
              )}
            </h1>
            {(showCompany || !isMobile) && (
              <div className="space-y-1 mt-2">
                <Link to="/trainings" className="block text-xs sm:text-sm inline-block">Trainings</Link><br />
                <Link to="/comingsoon" className="block text-xs sm:text-sm inline-block">Blog</Link><br />
                <Link to="/comingsoon" className="block text-xs sm:text-sm inline-block">Products</Link><br />
                <Link to="/about" className="block text-xs sm:text-sm inline-block">About</Link><br />
              </div>
            )}
          </div>

          <div className="relative">
            <h1
              className="text-sm sm:text-base font-bold cursor-pointer flex items-center"
              onClick={() => isMobile && setShowContact(!showContact)}
            >
              <span>Contact Us</span>
              {isMobile && (
                <span className={`transform ${showContact ? 'rotate-180' : 'rotate-0'} transition-transform duration-300 ml-2`}>
                  ▼
                </span>
              )}
            </h1>
            {(showContact || !isMobile) && (
              <address className="not-italic text-sm space-y-2 mt-2">
                <p>Corporate Office: 5th Floor, CC&Co, Cornerstone Premises, Prabhat Road, Deccan Gymkhana, Pune - 411004</p>
                <p>Registered Office: A-303, Samrajya Balwantpuram, Shivtirtha Nagar, Off. Paud Road, Pune - 411038</p>
                <p>Email: <a href="mailto:contactus@skilljourney.in" className="hover:underline">contactus@skilljourney.in</a></p>
                <p>WhatsApp: <a href="https://wa.me/+919699185758" target="_blank" rel="noopener noreferrer" className="hover:underline">+91 96991 85758</a></p>
              </address>
            )}
          </div>
        </div>
      </div>

      <div className="text-xs sm:text-sm text-gray-400 text-center py-2">
        <p>©2024 SkillJourney LLP.</p>
      </div>
    </footer>
  );
};

export default Footer;
