import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar';

const LoginPage: React.FC = () => {
  const [username, setUsername] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [error, setError] = useState<string>('');
  const navigate = useNavigate();

  const handleLogin = (event: React.FormEvent) => {
    event.preventDefault();
    
    if (username && password) {
      // You can add your authentication logic here
      navigate('/counsellor/dashboard'); // Redirect to dashboard on success
    } else {
      setError('Please enter both username and password.');
    }
  };

  return (
    <div className="flex flex-col min-h-screen">
      <CreateNavbar page="counsellor" />
      <main className="flex-grow bg-gray-100 flex items-center justify-center p-6">
        <div className="bg-white p-10 rounded-lg shadow-lg w-full max-w-lg sm:max-w-md">
          <h1 className="text-4xl font-bold text-center mb-8 text-gray-800">Counsellor Login</h1>
          <form onSubmit={handleLogin}>
            <div className="mb-6">
              <label htmlFor="username" className="block text-gray-700 text-lg font-medium mb-2">Username</label>
              <input
                type="text"
                id="username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 transition duration-200"
              />
            </div>
            <div className="mb-6">
              <label htmlFor="password" className="block text-gray-700 text-lg font-medium mb-2">Password</label>
              <input
                type="password"
                id="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                className="w-full px-4 py-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-sky-400 transition duration-200"
              />
            </div>
            {error && <div className="text-red-600 text-sm mb-6">{error}</div>}
            <button
              type="submit"
              className="w-full py-3 bg-sky-600 text-white font-semibold rounded-lg hover:bg-sky-800 transition duration-300"
            >
              Login
            </button>
          </form>
        </div>
      </main>
    </div>
  );
};

export default LoginPage;
