import { useNavigate } from "react-router-dom";
import { Question } from "../../api/SectionWiseInsertion";
import ArrowBackIosNewTwoToneIcon from '@mui/icons-material/ArrowBackIosNewTwoTone';
import Dragable from "../common/Dragable";
import { Stack, Step, StepConnector, stepConnectorClasses, StepIconProps, StepLabel, Stepper, styled } from "@mui/material";
import DoneTwoToneIcon from '@mui/icons-material/DoneTwoTone';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';import MoreHorizTwoToneIcon from '@mui/icons-material/MoreHorizTwoTone';
import { useEffect, useRef, useState } from "react";
import Popup from "../common/ConfirmationPopup";
import { getSectionData,  saveNumberToLocalStorage,  updateSectionData } from "../common/StorageUtil";
import { areListsIdentical } from "../common/DeepEqual";

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        top: 22,
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(95deg, rgb(0,255,0) 0%, rgb(34,139,34) 50%, rgb(0,128,0) 100%)',
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            backgroundImage: 'linear-gradient(95deg, rgb(0,255,0) 0%, rgb(34,139,34) 50%, rgb(0,128,0) 100%)',
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 3,
        border: 0,
        backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
        borderRadius: 1,
    },
}));

const ColorlibStepIconRoot = styled('div')<{
    ownerState: { completed?: boolean; active?: boolean; modified?:boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? theme.palette.grey[700] : '#fff',
    border: '2px solid #ccc',
    zIndex: 1,
    color: '#fff',
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
    ...(ownerState.active && {
        backgroundImage: 'radial-gradient(circle, rgba(51,175,29,1) 0%, rgba(51,175,29,0.8) 20%, rgba(51,175,29,0.6) 40%, rgba(51,175,29,0.4) 60%, rgba(51,175,29,0.2) 80%, rgba(51,175,29,0) 100%)',
        border: "none"
    }),
    ...(ownerState.completed && {
        border: '2px solid #33AF1D',
        backgroundColor: '#fff',
        color: '#33AF1D',
    }),
    ...(ownerState.modified && {
        border: '2px solid #FCB017',
        backgroundColor: '#fff',
        color: '#FCB017',
    }),
}));

function ColorlibStepIcon(props: any) {
    const { active, completed,modified, icon } = props;

    const icons = {
        done: <DoneTwoToneIcon />,
        current: <MoreHorizTwoToneIcon />,
        modified:<PriorityHighIcon/>
    };

    let iconToDisplay = null;
    if (active) {
        iconToDisplay = icons.current;
    } else if (completed) {
        iconToDisplay = icons.done;
    }else if(modified){
        iconToDisplay=icons.modified;
    }

    return (
        <ColorlibStepIconRoot ownerState={{ completed, active,modified }}>
            {iconToDisplay}
        </ColorlibStepIconRoot>
    );
}

interface SteaperProps {
    searchLogs: Question[];
    activeSectionIndex: number;
    setActiveSectionIndex: (history: number) => void;
    setDraftData:any,
    draftData:any
}

export const Steaper: React.FC<SteaperProps> = ({ searchLogs, activeSectionIndex, setActiveSectionIndex ,setDraftData,draftData}) => {
    const navigate = useNavigate();
    const [showConfirmation, setShowConfirmation] = useState(false);
    const useremail=localStorage.getItem("Email");

    const isMandatoryFilled = (sectionId: number) => {
        const useremail=localStorage.getItem("Email");
        const local_data=getSectionData(useremail,sectionId);
        const db_data=getSectionData(useremail,"DB_USER_Answers")
        const empty={};
        let isDataChanged='';
        if(local_data===undefined && db_data[sectionId]!==undefined){
            return 'g';
        }
        else if((local_data!==undefined) && (db_data[sectionId]!==undefined)){
          const data = db_data[sectionId];
          const answers= data?.questions;
          for(let iterator=0;iterator<answers.length;iterator++){
            const id =answers[iterator].id;
        
            if(local_data[id]===answers[iterator].reply){
              isDataChanged='g';
            }
            else if((local_data[id]==="")&&(db_data[sectionId]==="") ){
              return 'g';
            }else if(areListsIdentical(local_data[id],answers[iterator].reply))
            {
              isDataChanged='g';
            }else{
              return 'w';
            }
          }
    
          return isDataChanged;
        }
        else if((local_data!==undefined && db_data[sectionId]===undefined)&& Object.keys(local_data).length>0){
          const keys=Object.keys(local_data);
          for(let keys_iterator=0;keys_iterator<Object.keys(local_data).length;keys_iterator++){
            if(local_data[keys[keys_iterator]]==="" || areListsIdentical(local_data[keys[keys_iterator]],[])){
              return '';
            }
          }
          return 'w';
        }
        else{
          return '';
        }
       
      };

    const navigateBack = () => {
        activeSectionIndex > 0 ? setActiveSectionIndex(activeSectionIndex - 1) : setShowConfirmation(true);
        updateSectionData(useremail,'activeSectionIndex', activeSectionIndex - 1);
        updateSectionData(useremail, activeSectionIndex, draftData);
    };

    const handleActiveSectionIndex = (sectionId:number) => {
        setActiveSectionIndex(sectionId - 1);
        updateSectionData(useremail, activeSectionIndex, draftData);
        updateSectionData(useremail,'activeSectionIndex',sectionId - 1);
    };

   
      
    return (

        <div className="grid grid-cols-10 items-center px-5 pt-5 h-26">
            {showConfirmation && (
                <Popup
                    onYes={() => navigate('/home')}
                    onNo={() => setShowConfirmation(false)}
                />
            )}
            <div className="flex col-span-1 items-start grid grid-rows-2" onClick={navigateBack}>
                <ArrowBackIosNewTwoToneIcon />
            </div>
            <div className="col-span-9">
                <Dragable>
                    <Stack sx={{ width: '100%' }} spacing={4}>
                        <Stepper alternativeLabel activeStep={activeSectionIndex} connector={<ColorlibConnector />}>
                        {searchLogs.map((label, index) => {
                                let stepIcon = ColorlibStepIcon;
                                

                                const checkType = isMandatoryFilled(index);

                                return (
                                    <Step key={label.sectionId}>
                                        <div onClick={() => handleActiveSectionIndex(label.sectionId)}>
                                            <StepLabel StepIconComponent={(props) => (
                                                <ColorlibStepIcon 
                                                    {...props} 
                                                    active={index === activeSectionIndex} 
                                                    completed={checkType==='g'} 
                                                    modified={checkType==='w'}
                                                />
                                            )}>
                                                Step {label.sectionId}
                                            </StepLabel>
                                        </div>
                                    </Step>
                                );
                            })}

                        </Stepper>
                    </Stack>
                </Dragable>
            </div>
        </div>
    );
};
