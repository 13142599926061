import React from 'react';
import styled, { keyframes } from 'styled-components';
import logo from "../../assets/img/skillJourneyMainLogo.svg";

// Keyframes for spinner animation
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

// Styled container for the loading spinner
const SpinnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Full viewport height */
  background-color: #f8f9fa; /* Light background color */
  padding: 20px; /* Padding for small screens */
  box-sizing: border-box; /* Ensure padding does not affect height */
  text-align: center;
`;

// Container for spinner and logo
const SpinnerWrapper = styled.div`
  position: relative; /* To position the logo inside the spinner */
  width: 190px; /* Size for the spinner container */
  height: 190px; /* Size for the spinner container */
  
  @media (max-width: 600px) {
    width: 160px; /* Adjusted size for mobile */
    height: 160px; /* Adjusted size for mobile */
  }
`;

// Styled spinner element
const Spinner = styled.div`
  border: 28px solid #f3f3f3; /* Light grey */
  border-top: 28px solid #3498db; /* Blue color */
  border-radius: 50%;
  width: 100%; /* Fill the container */
  height: 100%; /* Fill the container */
  animation: ${spin} 1s linear infinite;
`;

// Circular background for the logo
const LogoContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 180px; /* Increased size to match the spinner */
  height: 180px; /* Increased size to match the spinner */
  background-color: #fff; /* Background color around the logo */
  border-radius: 50%; /* Circular shape */
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Optional shadow for effect */
  
  @media (max-width: 600px) {
    width: 150px; /* Adjusted size for mobile */
    height: 150px; /* Adjusted size for mobile */
  }
`;

// Styled logo
const Logo = styled.img`
  width: 140px; /* Increased size to fit inside the circular container */
  height: 140px; /* Increased size to fit inside the circular container */

  @media (max-width: 600px) {
    width: 120px; /* Adjusted size for mobile */
    height: 120px; /* Adjusted size for mobile */
  }
`;

const Tagline = styled.div`
  font-size: 25px;
  font-family: 'Noto Sans', sans-serif; /* Updated font family */
  color: #3498db; /* Blue color to match the spinner */
  text-align: center;
  padding-top: 10px;
  
  @media (max-width: 600px) {
    font-size: 20px; /* Adjusted size for mobile */
  }
`;

const LoadingSpinner: React.FC = () => {
  return (
    <SpinnerContainer>
      <SpinnerWrapper>
        <Spinner />
        <LogoContainer>
          <Logo src={logo} alt="SkillJourney Logo" />
        </LogoContainer>
      </SpinnerWrapper>
      <Tagline>From Learning to Leading</Tagline>
    </SpinnerContainer>
  );
};

export default LoadingSpinner;
