import { useEffect, useLayoutEffect, useState } from 'react';
import Sidebar from '../components/sidebar/Sidebar';
import { fetchUserSearchLogs } from '../api/Questionnaire';
import MainBody from '../components/QuestionnaireCointainer/Questionnaire';
import LoadingSpinner from '../components/common/LoadingSpinner';
import { storeUserData, updateSectionData } from '../components/common/StorageUtil';
import { FetechUserResponses } from '../api/FetchUserResponses';
import { isUserTokenValid } from '../components/common/ValidateToken';
import { useNavigate } from 'react-router-dom';

interface DraftData {
  [key: string]: any;
}

export default function Questionnaire() {
  const [searchLogs, setSearchLogs] = useState<any[]>([]); // Questionnaires
  const [error, setError] = useState<string | null>(null);
  const [sectionHistory, setSectionHistory] = useState<number[]>([]);
  const [activeSectionIndex, setActiveSectionIndex] = useState<number>(0);
  const [draftData, setDraftData] = useState<DraftData>({});
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [userAnswersHistory, setUserAnswersHistory] = useState<any[]>([]); // user answers history


  const navigate = useNavigate();

  // Fetch data function
  const fetchData = async () => {

    try {
      const isValid: any = await isUserTokenValid();

      if (isValid) {
        setLoading(true); // Start loading
        const data = await fetchUserSearchLogs();
        const userAnswers = await FetechUserResponses();
        const useremail = localStorage.getItem('Email');
        updateSectionData(useremail, "DB_USER_Answers", userAnswers);
        setUserAnswersHistory(userAnswers);
        setSearchLogs(data.questions);
        setError(null);
        if (data.questions.length > 0) {
          setSectionHistory([data.questions[0].sectionId]);
        }
      } else {

        navigate('/'); // Redirect to '/' on error
        throw "Invalid Token.."
      }
    } catch (error: any) {
      navigate('/'); // Redirect to '/' on error
      console.log(error.message);
      setError(error.message); // Set error state if needed
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    const handleBeforeUnload = async () => {
      const useremail = localStorage.getItem("Email");
      const isValid: any = await isUserTokenValid()
      if (isValid) {
        updateSectionData(useremail, activeSectionIndex, draftData);
      }
    };
    window.addEventListener('beforeunload', handleBeforeUnload);
    if (Object.keys(draftData).length > 0 || userAnswersHistory.length > 0) {
      storeUserData('emailIdPresent', true);
      storeUserData('editAllowed', true);
    };
  },);

  useLayoutEffect(() => {
    try {
      fetchData();
    } catch (error) {
      console.error('Error Fetching:', error);
      throw error;  // rethrow the error
    }
  }, []); // Empty dependency array means this effect runs once on mount

  // Show loading indicator if data is still being fetched
  if (loading) { return <LoadingSpinner /> }

  return (
    <div className="flex flex-col md:flex-row h-screen overflow-y-auto">
      <Sidebar
        searchLogs={searchLogs}
        activeSectionIndex={activeSectionIndex}
        setActiveSectionIndex={setActiveSectionIndex}
        draftData={draftData}
        setDraftData={setDraftData}
        userAnswersHistory={userAnswersHistory}
      />
      <MainBody
        searchLogs={searchLogs}
        error={error}
        sectionHistory={sectionHistory}
        setSectionHistory={setSectionHistory}
        activeSectionIndex={activeSectionIndex}
        setActiveSectionIndex={setActiveSectionIndex}
        draftData={draftData}
        setDraftData={setDraftData}
        userAnswersHistory={userAnswersHistory}
        setUserAnswersHistory={setUserAnswersHistory}
        setLoading={setLoading}
      />
    </div>
  );
}
