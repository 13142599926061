import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar'; // Adjust the import path as necessary
import Rating from '@mui/material/Rating'; // Import the Rating component from Material-UI
import { get_counsellor } from '../../api/Counsellor';
import { isUserTokenValid } from '../common/ValidateToken';
import LoadingSpinner from '../common/LoadingSpinner';

// Define the structure for counselor data from the API
interface AvailableSlot {
  date: string;
  slots: string[];
}

interface CounselorApiResponse {
  email: string;
  name: string;
  contact: number | null;
  profile_url: string;
  bio: string;
  additional_info: string;
  address: string;
  available_slots: {
    AvailableDates: AvailableSlot[];
  };
  specialty: string;
  rating: number; // Ensure rating is a number
}

// Define the structure for counselor data in the app
export interface Counselor {
  id: number;
  name: string;
  email: string;
  specialty: string;
  rating: number;
  photoUrl: string;
  bio: string;
  availableSlots: AvailableSlot[]; // Correctly reflect available slots
  additional_info: string;
}

const CounselorsPage: React.FC = () => {
  const [sortBy, setSortBy] = useState<string>('rating');
  const [specialtyFilter, setSpecialtyFilter] = useState<string>('All');
  const [loading, setLoading] = useState<boolean>(true); // Loading state
  const [counselors, setCounselors] = useState<Counselor[]>([]); // State for counselors
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  // Function to fetch and transform data
  const fetchData = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await isUserTokenValid();
      if (!isValid) {
        navigate('/login');
        return; // Stop further execution if not valid
      }
      const fetchedData: CounselorApiResponse[] = await get_counsellor(); // Fetch data from API

      // Transform data to fit the Counselor interface
      const transformedCounselors: Counselor[] = fetchedData.map((counselor, index) => ({
        id: index + 1, // Assign a unique ID
        name: counselor.name,
        email: counselor.email,
        specialty: counselor.specialty,
        rating: counselor.rating,
        photoUrl: counselor.profile_url,
        bio: counselor.bio,
        availableSlots: counselor.available_slots.AvailableDates,
        additional_info: counselor.additional_info
      }));

      setCounselors(transformedCounselors); // Update state with transformed data
    } catch (error: any) {
      console.error(error.message);
      setError('Failed to load counselors'); // Set a user-friendly error message
    } finally {
      setLoading(false); // Stop loading
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  // Extract unique specialties
  const uniqueSpecialties = counselors
    .map(counselor => counselor.specialty)
    .filter((value, index, self) => self.indexOf(value) === index);

  // Function to filter counselors based on selected criteria
  const filterCounselors = (counselors: Counselor[], specialtyFilter: string): Counselor[] => {
    return counselors.filter(counselor =>
      specialtyFilter === 'All' || counselor.specialty === specialtyFilter
    );
  };

  // Function to sort counselors based on selected criteria
  const sortCounselors = (counselors: Counselor[], sortBy: string): Counselor[] => {
    return [...counselors].sort((a, b) => {
      if (sortBy === 'rating') {
        return b.rating - a.rating;
      }
      return a.name.localeCompare(b.name);
    });
  };

  useEffect(() => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  }, []);

  // Get the filtered and sorted list of counselors
  const filteredCounselors = filterCounselors(counselors, specialtyFilter);
  const sortedCounselors = sortCounselors(filteredCounselors, sortBy);

  // Handle navigation to detailed view of a counselor
  const handleCardClick = (id: number) => {
    navigate(`/counselors/${id}`);
  };

  // Handle booking a counseling session
  const handleBookSession = (counselor: Counselor) => {
    navigate(`/book-session/${counselor.id}`, { state: { counselor } });
  };

  // Handle navigation to booked sessions page
  const handleViewBookedSessions = () => {
    navigate('/booked-sessions');
  };

  if (loading) {
    return <LoadingSpinner />; // Show spinner while loading
  }

  return (
    <>
      <CreateNavbar page='home' />
      <div className="bg-gray-50 min-h-screen p-6 md:p-8 font-sans">
        <div className="mb-8">
          <h1 className="text-3xl md:text-4xl font-bold text-center mb-4 text-gray-900 font-sans">Meet Our Counselors</h1>
          <div className="flex justify-center">
            <button
              onClick={handleViewBookedSessions}
              className="px-6 py-3 bg-sky-600 text-white font-semibold rounded-lg shadow-lg hover:bg-sky-700 transition duration-300"
            >
              View Booked Sessions
            </button>
          </div>
        </div>

        {/* Error State */}
        {error && (
          <div className="text-center text-red-600 mb-4">
            <p>{error}</p>
          </div>
        )}

        {/* Filtering and Sorting options */}
        {!loading && !error && (
          <div className="mb-8">
            <div className="flex flex-col md:flex-row md:items-center md:justify-end">
              <div className="mb-4 md:mb-0">
                <select
                  value={specialtyFilter}
                  onChange={(e) => setSpecialtyFilter(e.target.value)}
                  className="p-3 border rounded-lg border-gray-300 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 text-sm md:text-base"
                >
                  <option className="text-center" value="All">All</option>
                  {uniqueSpecialties.map((specialty, index) => (
                    <option className="text-center" key={index} value={specialty}>
                      {specialty}
                    </option>
                  ))}
                </select>
              </div>

              <div className="mb-4 md:mb-0 md:ml-4">
                <select
                  value={sortBy}
                  onChange={(e) => setSortBy(e.target.value)}
                  className="p-3 border rounded-lg border-gray-300 shadow-md focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-300 text-sm md:text-base"
                >
                  <option value="rating">Sort by Rating</option>
                  <option value="name">Sort by Name</option>
                </select>
              </div>
            </div>
          </div>
        )}

        {/* Counselors List */}
        {!loading && !error && sortedCounselors.length > 0 && (
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-6 md:gap-8">
            {sortedCounselors.map((counselor) => (
              <div
                key={counselor.id}
                className="bg-white p-6 rounded-lg shadow-lg border border-gray-200 transition-transform transform hover:scale-105 hover:shadow-xl flex flex-col"
              >
                <img
                  src={counselor.photoUrl}
                  alt={counselor.name}
                  className="w-32 h-32 rounded-full border-4 border-orange-500 mx-auto mb-4"
                />
                <h3 className="text-xl md:text-2xl font-semibold mb-2 text-gray-800 text-center">{counselor.name}</h3>
                <p className="text-gray-600 text-center mb-2">{/*<strong>Specialty:</strong> */}{counselor.specialty}</p>
                <div className="text-center mb-2">
                  <div className="flex justify-center mt-1">
                    <div className="justify-center text-gray-600 text-center mb-2">
                      {/*<strong>Rating:</strong>*/}
                    </div>
                    <div className="justify-center py-1 pl-2">
                      <Rating
                        name={`rating-${counselor.id}`}
                        value={counselor.rating}
                        precision={0.5}
                        readOnly
                        size="small"
                      />
                    </div>
                  </div>
                </div>
                <p className="text-gray-600 text-center mb-4 text-xs md:text-base">{/*<strong>Bio:</strong>*/} {counselor.bio}</p>
                <div className="flex-grow flex flex-col justify-end">
                  {/* Un-comment the button below if you want to enable the "View Details" button */}
                  {/* <button
                    onClick={() => handleCardClick(counselor.id)}
                    className="px-6 py-2 bg-orange-500 text-white font-semibold rounded-lg shadow-md hover:bg-orange-600 transition-colors duration-300 mb-4"
                  >
                    View Details
                  </button> */}
                  <button
                    onClick={() => handleBookSession(counselor)}
                    className="px-6 py-2 bg-orange-400 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300"
                  >
                    Book a Session
                  </button>
                </div>
              </div>
            ))}
          </div>
        )}
        {sortedCounselors.length === 0 && !loading && (
          <div>
            <p className="text-center text-gray-700">No counselors available.</p>
          </div>
        )}
      </div>
    </>
  );
};

export default CounselorsPage;
