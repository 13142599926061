function deepEqual(obj1: any, obj2: any): boolean {
    if (obj1 === obj2) return true;
    
    if (typeof obj1 !== 'object' || obj1 === null || typeof obj2 !== 'object' || obj2 === null) {
      return false;
    }
  
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);
  
    if (keys1.length !== keys2.length) return false;
  
    for (const key of keys1) {
      if (!keys2.includes(key) || !deepEqual(obj1[key], obj2[key])) {
        return false;
      }
    }
  
    return true;
  }

  function areListsIdentical<T>(list1: T[], list2: T[]): boolean {
    // Ensure both inputs are arrays
    if (!Array.isArray(list1) || !Array.isArray(list2)) {
        return false;
    }

    // Check if the arrays have the same length
    if (list1.length !== list2.length) {
        return false;
    }

    // Create a map to count occurrences of each element in the first list
    const countMap = new Map<T, number>();

    for (const item of list1) {
        countMap.set(item, (countMap.get(item) || 0) + 1);
    }

    // Decrement the count based on elements in the second list
    for (const item of list2) {
        const count = countMap.get(item);
        if (count === undefined) {
            return false; // item in list2 not found in list1
        }
        if (count === 1) {
            countMap.delete(item);
        } else {
            countMap.set(item, count - 1);
        }
    }

    // If the map is empty, all counts matched
    return countMap.size === 0;
}



export {deepEqual,areListsIdentical}