import React, { useEffect, useState } from "react";
import MainLogo from "../../assets/img/skillJourneyMainLogo.svg";
import { Question } from "../../api/SectionWiseInsertion";
import greenTik from '../../assets/img/green tick.svg';
import currentTik from '../../assets/img/green fill.svg';
import unTik from '../../assets/img/gray circle.svg';
import warningIcon from '../../assets/img/Warning.svg'; // Import warning icon
import activeLine from '../../assets/img/green line.svg';
import disableLine from '../../assets/img/gray line.svg';
import { Steaper } from "../steapers/Steaper";
import CreateNavbar from "../Navbar/Navbar";
import { getSectionData, removeSectionData,saveNumberToLocalStorage,updateSectionData } from "../common/StorageUtil";
import { areListsIdentical, deepEqual } from "../common/DeepEqual";
import {isMobile} from 'react-device-detect';

interface SidebarProps {
  searchLogs: Question[];
  activeSectionIndex: number;
  setActiveSectionIndex: (history: number) => void;
  draftData: any;
  setDraftData: any;
  userAnswersHistory:any[]
}

const Sidebar: React.FC<SidebarProps> = ({ searchLogs, activeSectionIndex, setActiveSectionIndex, draftData,
   setDraftData, userAnswersHistory}) => {
  const useremail=localStorage.getItem("Email");

  const isMandatoryFilled = (sectionId: number) => {
    const useremail=localStorage.getItem("Email");
    const local_data=getSectionData(useremail,sectionId);
    const db_data=getSectionData(useremail,"DB_USER_Answers");
    const empty={};
    let isDataChanged='';
    if(local_data===undefined && db_data[sectionId]!==undefined){
        return 'g';
    }
    else if((local_data!==undefined) && (db_data[sectionId]!==undefined)){
      const data = db_data[sectionId];
      const answers= data?.questions;
      for(let iterator=0;iterator<answers.length;iterator++){
        const id =answers[iterator].id;
    
        if(local_data[id]===answers[iterator].reply){
          isDataChanged='g';
        }
        else if((local_data[id]==="")&&(db_data[sectionId]==="") ){
          return 'g';
        }else if(areListsIdentical(local_data[id],answers[iterator].reply))
        {
          isDataChanged='g';
        }else{
          return 'w';
        }
      }

      return isDataChanged;
    }
    else if((local_data!==undefined && db_data[sectionId]===undefined)&& Object.keys(local_data).length>0){
      const keys=Object.keys(local_data);
      for(let keys_iterator=0;keys_iterator<Object.keys(local_data).length;keys_iterator++){
        if(local_data[keys[keys_iterator]]==="" || areListsIdentical(local_data[keys[keys_iterator]],[])){
          return '';
        }
      }
      return 'w';
    }
    else{
      return '';
    }
   
  };
  
  return (
    <>
      <div className="bg-Questionsidebar text-white md:w-1/5 w-full flex flex-col md:h-screen md:justify-center md:items-center">
        {isMobile ? (
          <CreateNavbar page="home" />
        ) : (
          <div className="py-8 md:border-b border-neutral-600 w-2/5 md:w-4/5 md:h-32">
            <div className="px-4">
            <a href="/" className="block">
              <img
                src={MainLogo}
                alt="Desktop Main skill journey Logo"
                className=""
              />
              </a>
            </div>
          </div>
        )}
        {!isMobile ? (
          <nav className="flex-1 w-full max-w-xs h-full overflow-y-auto py-5 mx-auto">
            {searchLogs.map((log, index) => {
              let statusImage = unTik; // Default to unTik
              let lineImage = disableLine; // Default to disableLine
              let checkType:any="";
             
              checkType=isMandatoryFilled(index);
              if(checkType==='g')
                statusImage=greenTik;
              else if(checkType==='w')
                statusImage=warningIcon;
              
              if (index === activeSectionIndex) {
                statusImage = currentTik;
              } 
              if (index > 0) {
                const prevStatus = searchLogs[index - 1].sectionId;
                if (prevStatus < log.sectionId || activeSectionIndex) {
                  lineImage = (statusImage === greenTik || statusImage === currentTik)
                    ? activeLine
                    : disableLine;
                } else {
                  lineImage = disableLine;
                }
              }
              return (
                <div
                  key={log.sectionId}
                  onClick={() => {
                    setActiveSectionIndex(index);
                    updateSectionData(useremail,'activeSectionIndex', index);
                    updateSectionData(useremail, activeSectionIndex, draftData);
                  }}
                  className="flex flex-col items-center text-gray-300 overflow-hidden"
                >
                  {index > 0 && (
                    <div className="flex justify-start w-52">
                      <img src={lineImage} alt={`line-${index}`} className="" />
                    </div>
                  )}
                  <div className="flex flex-row gap-2">
                    <div className="flex justify-center">
                      <img src={statusImage} alt={`statusTik-${index}`} />
                    </div>
                    <div className="flex-col overflow-hidden w-48 cursor-pointer">
                      <div className="text-xs text-black flex">STEP {log.sectionId}</div>
                      <div className="text-base font-normal text-black text-nowrap truncate">
                        {log.shortName}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </nav>
        ) : null}
      </div>
      {isMobile ? (
        <Steaper
          searchLogs={searchLogs}
          activeSectionIndex={activeSectionIndex}
          setActiveSectionIndex={setActiveSectionIndex}
          draftData={draftData}
          setDraftData={setDraftData}
        />
      ) : null}
    </>
  );
  
};

export default Sidebar;
