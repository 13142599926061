import React from 'react';

const NoSessions: React.FC<{ handleBookClick: () => void }> = ({ handleBookClick }) => {
  return (
    <div className="bg-gray-50 min-h-screen flex flex-col items-center justify-center">
      {/* No Sessions Message */}
      <div className="text-center mb-4">
        <h2 className="text-2xl font-bold text-gray-800 mb-2">No Booked Sessions</h2>
        <p className="text-base text-gray-600">
          You have no booked sessions at the moment. 
          <br />
          Start your journey towards better counseling by booking a session now!
        </p>
      </div>

      {/* Button to Book Session */}
      <button
        onClick={() => handleBookClick()}
        className="px-5 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 transition-colors duration-300 transform hover:scale-105"
      >
        Book Counseling Session
      </button>
    </div>
  );
};

export default NoSessions;
