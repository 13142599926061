type ApiEndpoints = {
    backendApi: string;
    kosmosApi: string;
};

const getApiEndpoint = (): ApiEndpoints => {
    const currentUrl = window.location.href;
    const isProduction = currentUrl.startsWith("https://salmon-mud-0047deb10.4.azurestaticapps.net") || currentUrl.startsWith("https://northstar.skilljourney.in")
    || currentUrl.startsWith("https://skilljourney.in") || currentUrl.startsWith("https://www.skilljourney.in/");

    if (isProduction) {
        return {
            backendApi: "https://northstar.azurewebsites.net/api/",
            kosmosApi: "https://kosmos-prod.azurewebsites.net/api/"
        };
    } else {
        return {
            backendApi: "https://staging-northstar.azurewebsites.net/api/",
            kosmosApi: "https://kosmos-staging.azurewebsites.net/api/"
        };
    }
};

export const BackendApi = (): string => getApiEndpoint().backendApi;

export const KosmosApi = (): string => getApiEndpoint().kosmosApi;
