import axios from 'axios';
import { BackendApi } from './apiConfig/ApiFormatter';

export const JourneyStatusCheck = async (): Promise<any> => {
    const apiUrl = BackendApi() + "checkJourneyStatus";
    try {
        const response = await axios.get(apiUrl, {
            headers: {
                'Authorization': `${localStorage.getItem('AccessToken')}`
            }
        });

        return response.data.data; // Return the response data directly
    } catch (error) {
        console.error('Error fetching user questionnaire:', error);
        throw error; // Rethrow error to handle it in the component
    }
};

export const PremiumJourneyStatusCheck = async (): Promise<any> => {
    const apiUrl = BackendApi() + "getPremiumJourneyStatus";
    try {
        const response = await axios.get(apiUrl, {
            headers: {
                'Authorization': `${localStorage.getItem('AccessToken')}`
            }
        });
        console.log("API Response:", response);  // Log the full response to verify structure

        // Correctly accessing the data from response
        if (response && response.data) {
            return response.data; // Return the data directly as it contains the 'journey_status'
        } else {
            console.error("Unexpected API response structure:", response);
            return null; // Return null if the structure is not as expected
        }
    } catch (error) {
        console.error('Error fetching premium journey status:', error);
        throw error; // Rethrow error to handle it in the component
    }
};

