import { formToJSON } from "axios";
import { isValidToken } from "../../api/CheckEmail";

export const isUserTokenValid = async () => {
      const token:any=localStorage.getItem('AccessToken')
      console.log(token);
      if(token===undefined || token===null){
        localStorage.clear();
        return false;
      }else if(token.length===0){
        localStorage.clear();
        return false;
      }
      const response:any=isValidToken(token);
      if(!response)
        localStorage.clear();
      return response;
}

