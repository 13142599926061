// Popup.tsx
import React from 'react';

type PopupProps = {
  onYes: () => void;
  onNo: () => void;
};

const Popup: React.FC<PopupProps> = ({ onYes, onNo }) => {

  return (
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50">
      <div className="bg-white rounded-lg p-6 max-w-lg w-full relative" onClick={(e) => e.stopPropagation()}>
        Are you sure you want to go back to Home Page?
        <div className="flex justify-end mt-4 space-x-4">
          <button
            className="border-2 border-solid hover:bg-blue-1000 hover:text-white px-4 py-2 rounded"
            onClick={() => { onYes(); }}
          >
            Yes
          </button>
          <button
            className="border-2 border-solid hover:bg-blue-1000 hover:text-white px-4 py-2 rounded"
            onClick={() => { onNo(); }}
          >
            No
          </button>
        </div>
      </div>
    </div>
  );
};

export default Popup;
