import React from 'react'
import user from '../../assets/img/premiumJourney/user.png'

function Profile() {
    return (
        <div>
            <div className="custom-width fixed right-10 hidden lg:block">
                <div className="border border-inherit rounded-md p-3">
                    <div className="flex border border-inherit rounded-md img-height">
                        <div className="pt-2 pl-2">
                            <p className="font-medium text-sm profile-name">Kumarswamy</p>
                            <p className="font-normal textxs profile-subText">Premium Career Journey</p>
                        </div>
                        <div className="res-margin">
                            <img className="w-24 h-24 mt-5 xl:pr-0 xl:w-28" src={user} alt="user" />
                        </div>
                    </div>
                </div>
            </div>
            <div className=" fixed right-3 w-32 profile-w hidden tab-profile">
                <div className="border border-inherit rounded-md p-2">
                    <div className="border border-inherit rounded-md">
                        <div className="res-margin mt-2">
                            <img className="w-16 h-16 mx-auto" src={user} alt="user" />
                        </div>
                        <div className="pt-1 text-center">
                            <p className="font-medium text-sm profile-name">Kumarswamy</p>
                            <p className="font-normal textxs profile-subText">Premium Career Journey</p>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile
