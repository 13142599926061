import React from 'react';
import SliderOpprtunities from './SliderOpportunities';
import { useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";

export default function Passion() {
  const navigate = useNavigate();
  const handleExploreClick = () => {
    navigate('/exploreopportunities'); // Adjust the path as necessary
  };

  return (
    <div className="text-center pt-7">
      <div className='md:text-3xl text-xl font-bold font-sans'>
        <span className='text-sky-600'>Find Your Passion,</span> 
        <span className='text-custom-orange'> Pursue Your Dream</span>
      </div>
      <div className='mt-3 font-bold text-slate-500 font-sans'>
      Gain insights into different career paths and set <br/> yourself up for long-term success
      </div>
      <div className="flex justify-center">
    <a 
        href="/exploreopportunities" 
        className="md:px-14 px-6 h-12 py-2.5 text-xl font-bold text-custom-orange underline tracking-wider transition-colors duration-300 hover:text-orange-600"
    >
        Explore Opportunities
    </a>
</div>
      <SliderOpprtunities />
    </div>
  );
}
