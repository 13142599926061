"use client";
import React, { useState, useEffect, useCallback, useRef } from "react";
import { ChevronLeftIcon } from "@heroicons/react/24/outline";
import OutsideClickHandler from "../common/outsideClick/OutsideClickHandler";
import Profile from "./Profile";
import PremiumSidebar from "../sidebar/PremiumSidebar";
import userOne from '../../assets/img/premiumJourney/user1.png'
import component from '../../assets/img/premiumJourney/component.png'
import arrowRight from '../../assets/img/premiumJourney/arrow-right.png'
import frame2 from '../../assets/img/premiumJourney/frame2.png'

export default function PremiumJourneyBOdy() {
    const [selectOptionOpen, setSidebarPopupOpen] = useState(false);
    const [isResponsive, setIsResponsive] = useState<boolean>(false);
    const sidebarRef = useRef<HTMLDivElement>(null);
    const [stickyDiv, setStickyDiv] = useState<HTMLDivElement | null>(null);

    const handleResize = useCallback(() => {
        setIsResponsive(window.innerWidth < 768);
    }, []);


    const handleOutsideClick = () => {
        if (isResponsive) {
            setSidebarPopupOpen(false);
        }
    };

    const handleSelectQuarter = (quarter: number) => {
        const elementId = quarter === 1 ? "quarter1" : "quarter2";
        document.getElementById(elementId)?.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
        handleResize();
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [handleResize]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            (entries) => {
                entries.forEach((entry) => {
                    if (entry.isIntersecting) {
                        setStickyDiv(entry.target as HTMLDivElement);
                    } else if (stickyDiv === entry.target) {
                        setStickyDiv(null);
                    }
                });
            },
            { threshold: [0] }
        );

        const panels = document.querySelectorAll(".panel-bg");
        panels.forEach((panel) => observer.observe(panel));

        return () => {
            panels.forEach((panel) => observer.unobserve(panel));
        };
    }, [stickyDiv]);

    useEffect(() => {
        if (stickyDiv) {
            stickyDiv.classList.add("sticky");
        } else {
            document.querySelectorAll(".panel-bg.sticky").forEach((div) => div.classList.remove("sticky"));
        }
    }, [stickyDiv]);


    return (
        <div>
            <div className="w-full md:mt-5">
                {/* Header Section */}
                <div className="container mx-auto custom-margin">
                    <div className=" fixed flex sm:hidden mb-5 pt-3 pr-2 bg-white">
                        {/* <button>
                            <ChevronLeftIcon className="h-7" />
                        </button> */}
                        <div className="flex shadow-md bg-white w-56 mob-w pl-1 rounded-lg px-3 py-2">
                            <img src={userOne} alt="user" className="w-16 h-16" />
                            <div className="pl-1">
                                <p className="font-medium text-base">Kumarswamy</p>
                                <p className="font-normal text-xs">Premium Career Journey</p>
                            </div>
                        </div>
                        <div className="w-36 ml-3 mob-w2">
                            <div className="bg-custom relative h-20 rounded-lg">
                                <p className="bg-white text-black font-semibold w-36 mob-w2 h-10 pt-2 text-center rounded-lg absolute bottom-10">
                                    Yr:1(Qr-1)
                                </p>
                                <p
                                    className="w-36 mob-w2 h-10 text-center pt-12 selectText"
                                    onClick={() => {
                                        setSidebarPopupOpen(true);
                                    }}
                                >
                                    Select option
                                </p>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Sidebar for Mobile */}
                {selectOptionOpen && isResponsive && (
                    <OutsideClickHandler onOutsideClick={handleOutsideClick} targetSelector=".specific-target-class">
                        <div className="md:hidden fixed inset-0 z-50 bg-opacity-50 bg-black">
                            <div className="relative z-50 top-36 left-48 mob-left" ref={sidebarRef}>
                                {/* <Sidebar visible={true} onSelectQuarter={handleSelectQuarter} activeQuarter={1} /> */}
                                <PremiumSidebar visible={true} onSelectQuarter={handleSelectQuarter} activeQuarter={1} />
                            </div>
                        </div>
                    </OutsideClickHandler>
                )}

                {/* Main Content and Sidebar for Desktop */}

                <div>

                    <div className="flex pt-28 sm:pt-5 md:pt-0 ">
                        <div className="container mx-auto custom-margin sm:w-3/4 md:w-3/4 lg:w-3/4 xl:w-3/4 sm:mx-3.5 main-box-shadow rounded-lg sm:pt-2 sm:pr-2 sm:pl-2 lg:pt-3 lg:pr-4 lg:pl-4">
                            <div className="year1-quarter1" id="quarter1">
                                <div className="panel-bg rounded-b-none rounded-t-lg md:rounded-t-lg md:rounded-b-lg px-4 py-4 sm:h-36">
                                    <div className="flex">
                                        <img className="w-6 h-6 desk-icon" src={component} alt="icon" />
                                        <img className="w-7 h-7 mob-icon" src={arrowRight} alt="icon" />
                                        <p className="text-base text-white font-semibold mb-3 pl-3">Year 1: Quarter 1</p>
                                    </div>
                                    <p className="text-base text-white leading-8 font-normal pl-9">
                                        <span className="font-semibold">Goal:</span> Gain a foundational understanding of financial concepts
                                        and build a solid base for career advancement.
                                    </p>
                                </div>
                                <div className="content">
                                    <p className="text-base font-semibold pt-3 pl-4 lg:pl-4 sm:pl-2 plantext-color">Plan:</p>
                                    <ul className="mt-2.5 list-disc sm:pl-6 lg:pl-11 sm:pr-2 flex shrink-1 flex-col w-80 sm:w-full mx-auto">
                                        <li className="text-base font-normal leading-6 ml-3 md:ml-0">
                                            <span className="font-semibold">Learn the Fundamentals: </span>
                                            Enroll in an online course on "Financial Accounting Fundamentals" (e.g., Coursera, edX, or Udemy).
                                            Focus on understanding key concepts like accounting equations, balance sheets, income statements,
                                            and cash flow statements.
                                        </li>
                                        <p className="text-base font-normal mt-2.5 ml-3 md:ml-0">
                                            <span className="font-medium">Recommended Course: </span>
                                            Financial Accounting Fundamentals Specialization by the University of Illinois Urbana-Champaign on
                                            Coursera <span className="break-words">
                                                (https://www.coursera.org/specializations/financial-accounting-fundamentals)
                                            </span>
                                        </p>

                                        <li className="text-base font-normal leading-6 mt-2.5 ml-2.5 md:ml-0">
                                            <span className="font-semibold"> Build Your Network: </span>
                                            Attend virtual or in-person networking events for finance professionals. Connect with individuals on
                                            LinkedIn, join relevant online communities, and participate in discussions.
                                        </li>
                                        <p className="text-base font-normal mt-2.5 ml-3 md:ml-0">
                                            <span className="font-medium">Recommended Networking Events: </span>
                                            The Accounting & Finance Network offers various online and in-person networking events
                                            <span className="break-words">(https://www.accountingfinance.network/).</span>
                                        </p>

                                        <li className="text-base font-normal leading-6 mt-2.5 ml-2.5 md:ml-0">
                                            <span className="font-semibold">Start Your Certification Journey: </span>
                                            Begin studying for the Certified Bookkeeper (CB) certification. This foundational certification will
                                            enhance your credibility and demonstrate your commitment to the field.
                                        </li>
                                        <p className="text-base font-normal mt-2.5 ml-3 md:ml-0">
                                            <span className="font-medium">Recommended Certification: </span>
                                            Certified Bookkeeper (CB) offered by the American Institute of Professional Bookkeepers
                                            <span className="break-words">(https://www.bookkeepers.org/).</span>
                                        </p>
                                    </ul>
                                    <div className="bg-custom rounded-none md:rounded-t-lg md:rounded-b-lg mt-6 p-8">
                                        <p className="text-base font-semibold">Actionable:</p>
                                        <ol className="list-decimal pl-6">
                                            <li className="text-base font-normal mt-3">
                                                <span className="font-semibold">Week 1-4: </span>Research and select a financial accounting course
                                                that best fits your learning style. Enroll and begin studying.
                                            </li>
                                            <li className="text-base font-normal mt-4">
                                                <span className="font-semibold">Week 5-8: </span>Start building your network on LinkedIn and join
                                                relevant online communities.
                                            </li>
                                            <li className="text-base font-normal mt-4">
                                                <span className="font-semibold">Week 9-12: </span>Dedicate time each week to studying for the
                                                Certified Bookkeeper (CB) exam.
                                            </li>
                                        </ol>
                                    </div>

                                    <div className="bg-frame mt-6">
                                        {/* <img src="/images/frame.png" alt="" className="" /> */}
                                        <div className="tab-custom w-56 pt-5 lg:w-80 pl-12 lg:pl-28 lg:pt-6">
                                            <p className="text-2xl font-semibold text-white">Keep it up</p>
                                            <p className="text-xs font-normal text-ecolor">You have completed 1st quarter of the year-1</p>
                                            <p className="text-semibold text-sm mt-3 year-color">Year 1</p>
                                            <img className="w-8 pt-2" src={frame2} alt="user" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="year1-quarter2 mt-10" id="quarter2">
                                <div className="panel-bg rounded-b-none rounded-t-lg sm:rounded-t-lg sm:rounded-b-lg px-4 py-4 sm:h-40 md:h-36">
                                    <div className="flex">
                                        <img className="w-6 h-6" src={component} alt="icon" />
                                        <p className="text-base text-white font-semibold mb-3 pl-3">Year 1: Quarter 2</p>
                                    </div>
                                    <p className="text-base text-white leading-8 font-normal pl-9">
                                        <span className="font-semibold">Goal:</span> Gain a foundational understanding of financial concepts
                                        and build a solid base for career advancement.
                                    </p>
                                </div>
                                <div className="content">
                                    <p className="text-base font-semibold pt-3 pl-4 lg:pl-4 sm:pl-2 plantext-color">Plan:</p>
                                    <ul className="mt-2.5 list-disc sm:pl-6 lg:pl-11 sm:pr-2 flex shrink-1 flex-col w-80 sm:w-full mx-auto">
                                        <li className="text-base font-normal leading-6 ml-3 md:ml-0">
                                            <span className="font-semibold">Learn the Fundamentals: </span>
                                            Enroll in an online course on "Financial Accounting Fundamentals" (e.g., Coursera, edX, or Udemy).
                                            Focus on understanding key concepts like accounting equations, balance sheets, income statements,
                                            and cash flow statements.
                                        </li>
                                        <p className="text-base font-normal mt-2.5 ml-3 md:ml-0">
                                            <span className="font-medium">Recommended Course: </span>
                                            Financial Accounting Fundamentals Specialization by the University of Illinois Urbana-Champaign on
                                            Coursera <span className="break-words">
                                                (https://www.coursera.org/specializations/financial-accounting-fundamentals)
                                            </span>
                                        </p>

                                        <li className="text-base font-normal leading-6 mt-2.5 ml-3 md:ml-0">
                                            <span className="font-semibold"> Build Your Network: </span>
                                            Attend virtual or in-person networking events for finance professionals. Connect with individuals on
                                            LinkedIn, join relevant online communities, and participate in discussions.
                                        </li>
                                        <p className="text-base font-normal mt-2.5 ml-3 md:ml-0">
                                            <span className="font-medium">Recommended Networking Events: </span>
                                            The Accounting & Finance Network offers various online and in-person networking events
                                            <span className="break-words">(https://www.accountingfinance.network/).</span>
                                        </p>

                                        <li className="text-base font-normal leading-6 mt-2.5 ml-3 md:ml-0">
                                            <span className="font-semibold">Start Your Certification Journey: </span>
                                            Begin studying for the Certified Bookkeeper (CB) certification. This foundational certification will
                                            enhance your credibility and demonstrate your commitment to the field.
                                        </li>
                                        <p className="text-base font-normal mt-2.5 ml-3 md:ml-0">
                                            <span className="font-medium">Recommended Certification: </span>
                                            Certified Bookkeeper (CB) offered by the American Institute of Professional Bookkeepers
                                            <span className="break-words">(https://www.bookkeepers.org/).</span>
                                        </p>
                                    </ul>
                                    <div className="bg-custom rounded-none md:rounded-t-lg md:rounded-b-lg mt-6 p-8">
                                        <p className="text-base font-semibold">Actionable:</p>
                                        <ol className="list-decimal pl-6">
                                            <li className="text-base font-normal mt-3">
                                                <span className="font-semibold">Week 1-4: </span>Research and select a financial accounting course
                                                that best fits your learning style. Enroll and begin studying.
                                            </li>
                                            <li className="text-base font-normal mt-4">
                                                <span className="font-semibold">Week 5-8: </span>Start building your network on LinkedIn and join
                                                relevant online communities.
                                            </li>
                                            <li className="text-base font-normal mt-4">
                                                <span className="font-semibold">Week 9-12: </span>Dedicate time each week to studying for the
                                                Certified Bookkeeper (CB) exam.
                                            </li>
                                        </ol>
                                    </div>

                                    <div className="bg-frame mt-6">
                                        <div className="w-56 pt-5 lg:w-80 pl-12 lg:pl-28 lg:pt-6">
                                            <p className="text-2xl font-semibold text-white">Keep it up</p>
                                            <p className="text-xs font-normal text-ecolor">You have completed 1st quarter of the year-1</p>
                                            <p className="text-semibold text-sm mt-3 year-color">Year 1</p>
                                            <img className="w-8 pt-2" src={frame2} alt="user" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <Profile></Profile>
                        </div>
                    </div>


                    <div className="hidden md:block w-1/4 pl-7">
                        {/* <Sidebar visible={isResponsive} onSelectQuarter={handleSelectQuarter} activeQuarter={1} /> */}
                        <PremiumSidebar visible={isResponsive} onSelectQuarter={handleSelectQuarter} activeQuarter={1} />
                    </div>
                </div>
            </div>
        </div>
    );
}
