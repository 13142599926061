import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import CreateNavbar from '../Navbar/Navbar';

const SuccessPage: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleGoBack = () => {
    navigate('/'); // Navigate to the home page or wherever you want
  };

  // Check if the page is being accessed directly
  const fromRegistration = location.state?.fromRegistration;

  if (!fromRegistration) {
    // If not navigated from a valid source, redirect to home or show an error
    navigate('/home');
    // return null;
  }

  return (
    <>
      <CreateNavbar page='home' />
      <div className="bg-gray-100 min-h-screen flex items-center justify-center">
        <div className="bg-white p-6 rounded-lg shadow-lg text-center">
          <h1 className="text-2xl font-bold text-gray-800 mb-4">Registration Successful!</h1>
          <p className="text-gray-600 mb-4">You’ve successfully registered for the course.</p>
          <button
            onClick={handleGoBack}
            className="px-4 py-2 bg-sky-600 text-white rounded-lg"
          >
            Go Back to Home
          </button>
        </div>
      </div>
    </>
  );
};

export default SuccessPage;
