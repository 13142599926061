// Define the type for the messages object
type MessageColors = "#C5F7DC" | "#FFE8C3" | "#FFD700";

interface Messages {
  [key: string]: string[];
}

export const messages: Messages = {
  "#C5F7DC": ["submitted", "successfully", "paid"],
  "#FFE8C3": ["not inserted", "not deleted", "ValueError", "Failed", "Failed to fetch", "not found", "Response",
    "exceeded the editing limit", "Wrong", "problem", "failed", 'already' ],
  "#FFD700": ["No changes detected",],
};

// Function to get the color based on the message
export const messagesColor = (message: string): MessageColors => {
  for (const [key, values] of Object.entries(messages)) {
    if (values.some((v) => message.includes(v))) {
      return key as MessageColors; // Type assertion to ensure correct type
    }
  }
  return "#FFD700"; // Default color
};

// Function to get the status text based on the message
export const getStatusText = (message: string): string => {
  if (messages["#C5F7DC"].some((v) => message.includes(v))) {
    return "Success";
  }
  if (messages["#FFE8C3"].some((v) => message.includes(v))) {
    return "Warning";
  }
  return ""; // Default empty string if no match
};
