import React, { useEffect, useState } from 'react';
import ArrowLeft from '../../assets/img/journey/arrow-left.svg'
import MobileArrow from '../../assets/img/journey/arrow-mobile.svg'
import CreateNavbar from '../Navbar/Navbar';
import { RazorpayButton } from '../common/RazorPay';
import GridImageSteaper from '../steapers/GridImageSteaper';
import roadImage1 from '../../assets/img/journey/stop-01.svg'
import roadImage2 from '../../assets/img/journey/stop-02.svg'
import roadImage3 from '../../assets/img/journey/stop-03.svg'
import roadImage4 from '../../assets/img/journey/stop-04.svg'
import roadImage5 from '../../assets/img/journey/stop-05.svg'
import { CellToJourneyPart } from './Shutter';
import streetImage1 from '../../assets/img/journey/street-1.svg'
import streetImage2 from '../../assets/img/journey/street-2.svg'
import streetImage3 from '../../assets/img/journey/street-3.svg'
import streetImage4 from '../../assets/img/journey/street-4.svg'
import streetImage5 from '../../assets/img/journey/street-5.svg'
import ConvertJourney from './ConvertJourney';
import { isMobile } from 'react-device-detect';

interface BasicJourneyBodyProps {
    userName: string;
    journeyPart: number;
    setShutter: (History: boolean) => void;
    setJourneyPart: (History: number) => void;
    standardJourney: any;
}

const BasicJourneyBody: React.FC<BasicJourneyBodyProps> = ({ userName, journeyPart, setShutter, setJourneyPart, standardJourney }) => {
    const [isVisible, setIsVisible] = useState(true);

    const cellToJourneyPart: { web: CellToJourneyPart; mobile: CellToJourneyPart } = {
        web: {
            31: 0, 36: 0,
            24: 1, 29: 1,
            16: 2, 21: 2, 22: 2,
            13: 3, 18: 3,
            7: 4, 12: 4
        },
        mobile: { 1: 0, 2: 1, 3: 2, 4: 3, 5: 4 }
    };

    const handleCellClick = (cellNumber: number) => {
        const journeyPart = !isMobile ? cellToJourneyPart.web[cellNumber] : cellToJourneyPart.mobile[cellNumber];
        if (journeyPart !== undefined) {
            setJourneyPart(journeyPart);
        }
    };
    const imageMapping: { [key: number]: string } = { 0: roadImage1, 1: roadImage2, 2: roadImage3, 3: roadImage4, 4: roadImage5 };
    const streetMapping: { [key: number]: string } = { 0: streetImage1, 1: streetImage2, 2: streetImage3, 3: streetImage4, 4: streetImage5 };
    const selectedImage = !isMobile ? (imageMapping[journeyPart] || roadImage1) : (streetMapping[journeyPart] || streetImage1);

    useEffect(() => {
        if (!isVisible) {
            setTimeout(() => setShutter(true), 500);
        }
    }, [isVisible, setShutter]);

    return (
        <div className={`flex transition-opacity duration-500 ${isVisible ? 'opacity-100' : 'opacity-0'}`}>
            <div className={`flex flex-col justify-between md:gap-2 ${isMobile ? '' : 'h-full'} w-dvw`}>
                <CreateNavbar page={'home'} />
                {!isMobile
                    ? (
                        <div className="flex w-screen">
                            <div className="flex flex-col justify-between w-2/5 ">
                                <div className='flex px-8 gap-1 text-base text-description-text items-center'>
                                    <img src={ArrowLeft} alt='ArrowLeft' className='size-6 cursor-pointer' onClick={() => setIsVisible(false)} />
                                </div>
                                {/*
                                <div className='flex justify-center items-center py-2'>
                                    <div className=' flex flex-col justify-center items-center container shadow-xl px-4 py-2 rounded-lg bg-white w-96 gap-2' style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}>
                                        <p className='text-center'>Hey there! I can give you a detailed information on every year.</p>
                                        <RazorpayButton buttonText='Click here for Premium Journey' />
                                    </div>
                                </div>*/}
                                <GridImageSteaper
                                    src={selectedImage}
                                    rows={10} // Adjust the number of rows as necessary
                                    cols={5} // Adjust the number of columns as necessary
                                    onCellClick={handleCellClick}
                                    containerClassName=""
                                    imageClassName=""
                                />
                            </div>
                            <div className="flex flex-col justify-start w-3/5 gap-4 py-3">
                                <div className=' flex pr-8'>
                                    <div className='cointainer shadow-xl p-4 rounded-lg' style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}>
                                        <div className='flex gap-1 pb-2'>
                                            <div className='bg-blue-1000 w-1' />
                                            <div>
                                                <div className='leading-4'>Standard Career Journey for</div>
                                                <div className='font-bold text-2xl'>{userName}</div>
                                            </div>
                                        </div>
                                        <div className=' flex-grow overflow-y-auto' style={{ maxHeight: '74vh' }}>
                                            <ConvertJourney standardJourney={standardJourney} journeyPart={journeyPart} setJourneyPart={setJourneyPart} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    : (
                        <div className={`flex flex-col gap-4 px-4`} >
                            <div className='flex justify-between pt-1'>
                                <div className='flex items-center cointainer shadow-xl p-3 rounded-lg' style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}><img src={MobileArrow} alt='ArrowLeft' className='size-4 cursor-pointer' onClick={() => setIsVisible(false)} /></div>
                                <GridImageSteaper
                                    src={selectedImage}
                                    rows={1} // Adjust the number of rows as necessary
                                    cols={5} // Adjust the number of columns as necessary
                                    onCellClick={handleCellClick}
                                    containerClassName=""
                                    imageClassName=""
                                />
                            </div>
                            <div className=''>
                                <div className=' flex justify-center items-center'>
                                    <div className='cointainer shadow-xl p-4 rounded-lg' style={{ boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.2)' }}>
                                        <div className='flex gap-1 pb-2'>
                                            <div className='bg-blue-1000 w-1' />
                                            <div>
                                                <div className='text-xs'>Standard Career Journey for</div>
                                                <div className='font-bold text-xl leading-5'>{userName}</div>
                                            </div>
                                        </div>
                                        <div className=' flex-grow overflow-y-auto' style={{ maxHeight: window.innerHeight < 860 ? '50vh' : '65vh' }}>
                                            <ConvertJourney standardJourney={standardJourney} journeyPart={journeyPart} setJourneyPart={setJourneyPart} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* 
                            <div className='flex justify-center items-center h-1/8'>
                                 <RazorpayButton buttonText='Premium Journey' />
                             </div>
                             */}
                            {/* Footer for Mobile View */}
                            <footer className='flex justify-center p-2 mt-10 text-xs text-gray-500'>
                                All rights reserved by @skilljourney {new Date().getFullYear()}
                            </footer>
                        </div>
                    )}
            </div>
        </div>
    );
}

export default BasicJourneyBody;
