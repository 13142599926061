import axios from 'axios';
import { BackendApi } from "./apiConfig/ApiFormatter";

export const CheckEmail = async (idToken: string): Promise<any> => {
    const apiUrl = `${BackendApi()}getUserMetadata`;

    try {
        const { data } = await axios.post(apiUrl, {}, {
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${idToken}`,
              }
        });

        // Handle cases where data fields might be undefined
        const emailIdPresent = data.emailIdPresent !== undefined ? data.emailIdPresent.toString() : 'false';
        const editAllowed = data.editAllowed !== undefined ? data.editAllowed.toString() : 'false';
        const journeyExist = data.journeyExist !== undefined ? data.journeyExist.toString() : 'false';

        // Now `premiumJourneyStatus` is a string instead of an object
        const premiumJourneyStatus = data.premiumJourneyStatus || 'unknown';
        const userLevel = data.userLevel || '';

        // Store values as strings in localStorage
        localStorage.setItem('Email', data.email || '');
        localStorage.setItem('emailIdPresent', emailIdPresent);
        localStorage.setItem('editAllowed', editAllowed);
        localStorage.setItem('journeyExist', journeyExist);
        localStorage.setItem('premiumJourneyStatus', premiumJourneyStatus); // Simplified key
        localStorage.setItem('userLevel', userLevel);

        return data;
    } catch (error) {
        console.error("Error checking email:", error);
        return null;
    }
};



interface TokenValidationResponse {
    message: string;
    isValid: any;
    Email: string;
  }
  export const isValidToken = async (idToken: string): Promise<TokenValidationResponse | null> => {
      const apiUrl = `${BackendApi()}isTokenValid`;
  
      try {
          const response = await fetch(apiUrl, {
              method: 'GET',
              headers: {
                  'Authorization': `${idToken}`, // Use 'Bearer' prefix for token-based authorization
                  'Content-Type': 'application/json'
              }
          });
  
          if (!response.ok) {
              throw new Error(`HTTP error! Status: ${response.status}`);
          }
  
          const data: TokenValidationResponse = await response.json();
          return data.isValid;
      } catch (error) {
          console.error("In Valid Token:", error);
          return null;
      }
  };
