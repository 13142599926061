import { BackendApi } from "./apiConfig/ApiFormatter";


export const CreateUser = async (): Promise<Response> => {
    const uploadUrl = BackendApi() + "uploadUserQuestionnaire";
    const idToken = localStorage.getItem('AccessToken'); // Fetch the ID token from session storage

    const data = {
        username: localStorage.getItem('Email'),
        questionnaire: []
    };

    const formDataObj = new FormData();
    formDataObj.append('data', JSON.stringify(data));

    const response = await fetch(uploadUrl, {
        method: 'POST',
        headers: {
            'Authorization': `${idToken}`
        },
        body: formDataObj
    });

    return response;
};
